
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { Config } from 'src/app/config';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-adm',
  templateUrl: './adm.component.html',
  styleUrls: ['./adm.component.scss']
})
export class AdmComponent implements OnInit {

  constructor(private service: PagesService, public dialog: MatDialog) { }
  panelOpenState = false;
  listBanners = []
  config = new Config()
  subs$: Subscription
  ngOnInit() {
    this.listBanners = []
    this.subs$ = this.service.getBanners().subscribe((res: any) => {
      let ret = []
      ret = res.data
      for (let i = 0; i < res.data.length; i++) {
        ret[i]["url_foto"] = this.config.URL_BANNER.concat(res.data[i]["banner"])
        this.listBanners.push(ret[i])
      }
    }, err => {
      // alert("Houve um erro. Redirecionando para a tela de Login...")
      // this.service.logoff()
      console.log(err)
    })
  }

  new() {
    const dialogRef = this.dialog.open(DialogNewBanner, {
      height: '600px',
      width: '800px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit()
    });
  }

  excluir(item) {
    let c = confirm("Deseja exluir o banner?")
    if (c) {
      this.service.deleteFile(item.banner).subscribe((res) => {
      }, err => {
        console.log(err)
      })
      this.service.deleteBanner(item.id).subscribe((res: any) => {
        if (res.data) {
          this.ngOnInit()
        } else {
          alert("Houve um erro ao excluir o banner")
        }
      }, err => {
        alert("Houve um erro ao excluir o banner")
        console.error(err)
      })
    }

  }

  ngOnDestroy(): void {
    if (this.subs$) this.subs$.unsubscribe()
  }

}

@Component({
  selector: 'new-banner',
  styleUrls: ['./adm.component.scss'],
  templateUrl: 'modal.html',
})
export class DialogNewBanner {
  constructor(
    public dialogRef: MatDialogRef<DialogNewBanner>,
    @Inject(MAT_DIALOG_DATA) public data: any, private uploadService: PagesService, private formBuilder: FormBuilder) { }


  form: FormGroup;
  uploadResponse;

  ngOnInit() {
    this.form = this.formBuilder.group({
      avatar: [''],
      link: ['']
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('avatar').setValue(file);
    }
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('avatar', this.form.get('avatar').value);

    this.uploadService.uploadFile(formData, this.form.get('link').value)
      .subscribe((res: any) => {
        if (res.data == "tamanhoIncorreto") {
          alert("A imagem não segue os padrões solicitados!")
          return
        }
        this.uploadResponse = res.data;
        alert(res.data)
        if (!res.erro) {
          this.dialogRef.close()
        }
      }, err => {
        console.log(err);
        alert("Houve um erro ao enviar o banner!")
      });
  }

  close() {
    this.dialogRef.close()
  }
}