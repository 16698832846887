import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from 'src/app/config';

@Component({
  selector: 'card-imovel',
  templateUrl: './card-imovel.component.html',
  styleUrls: ['./card-imovel.component.scss']
})
export class CardImovelComponent implements OnInit {
  config = new Config()
  defaultImage = this.config.DEFULT_IMAGE
  @Input() dataIm;

  constructor(private router: Router) { }
  ngOnInit() {
    if (this.dataIm.suite != 0)
      this.dataIm.quarto = parseInt(this.dataIm.quarto) + parseInt(this.dataIm.suite)

  }

  routerPage(idIm) {
    this.router.navigateByUrl(`/resultado/${idIm}`)
  }


}
