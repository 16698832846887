<mat-card>
    <h4>Contato</h4>
    <p>Envie um e-mail para a imobiliária!</p>
    <mat-form-field class="example-full-width">
        <input matInput placeholder="Nome*" [formControl]="NOME">
        <mat-error *ngIf="NOME.hasError('required')">
            Nome é <strong>obrigatório</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <input matInput placeholder="Telefone / Celular(WhatsApp)*" [formControl]="TELEFONE" type="tel">
        <mat-error *ngIf="TELEFONE.hasError('required')">
            Telefone é <strong>obrigatório</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <input matInput placeholder="E-mail (opcional)" [formControl]="EMAIL">
        <mat-error *ngIf="EMAIL.hasError('email')">
            Insira um e-mail válido
        </mat-error>
    </mat-form-field>

    <mat-radio-group *ngIf="curretRoute == '/fale-conosco'" aria-label="Sobre o imóvel*" [formControl]="SOBRE">
        <mat-radio-button checked value="Vender">Vender</mat-radio-button>
        <mat-radio-button value="Alugar">Alugar</mat-radio-button>
        <mat-error *ngIf="SOBRE.hasError('required')">
            Campo obrigatório
        </mat-error>
    </mat-radio-group>


    <mat-form-field class="example-full-width" *ngIf="curretRoute == '/fale-conosco'">
        <mat-label>Tipo do imóvel*</mat-label>
        <mat-select [formControl]="TIPO">
            <mat-option *ngFor="let topping of options" [value]="topping">{{topping}}</mat-option>
        </mat-select>
        <mat-error *ngIf="TIPO.hasError('required')">
            Campo obrigatório
        </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <textarea matInput placeholder="Mensagem*" [formControl]="MENSAGEM"></textarea>
        <mat-error *ngIf="MENSAGEM.hasError('required')">
            Mensagem é <strong>obrigatória</strong>
        </mat-error>
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="contato()"
        [disabled]="EMAIL.hasError('email') || TELEFONE.hasError('required') || NOME.hasError('required') || MENSAGEM.hasError('required') || !sendding">
        Enviar
    </button>

</mat-card>