<br>
<div class="container">
    <div class="container" name="top">
        <br>
        <h1 *ngIf="forSaleOrLocation == 0"><strong>{{length}}</strong> imóveis à venda</h1>
        <h1 *ngIf="forSaleOrLocation == 1"><strong>{{length}}</strong> imóveis para alugar</h1>
        <br>
    </div>

    <form [formGroup]="busca" style="width: 100%;">
        <div class="row">
            <div class="col">
                <div class="example-container">
                    <mat-form-field>
                        <mat-label>Imóvel para</mat-label>
                        <mat-select [(value)]="toggleForSaleOrLocation"
                            (selectionChange)="changeForSaleOrLocation($event.value)">
                            <mat-option value="Alugar">
                                Alugar
                            </mat-option>
                            <mat-option value="Comprar">
                                Comprar
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="col">
                <div class="example-container">
                    <mat-form-field>
                        <mat-label>Cidade</mat-label>
                        <mat-select [(value)]="cidade_principal"
                            (selectionChange)="getNeighborhood($event.value);getServerData()" formControlName="CITY">
                            <mat-option *ngFor="let city of cities" [value]="city.cidade_im">
                                {{city.cidade_im}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="col">
                <div class="example-container">
                    <mat-form-field>
                        <mat-label>Bairro</mat-label>
                        <mat-select formControlName="NEIGHBORHOOD" (selectionChange)="getServerData()">
                            <mat-option *ngFor="let neighborhood of neighborhoods" [value]="neighborhood.bairro_im">
                                {{neighborhood.bairro_im}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="col" *ngIf="forSaleOrLocation == 0">
                <div class="example-container">
                    <mat-form-field>
                        <mat-label>Valor mínimo</mat-label>
                        <mat-select formControlName="MIN_VALUE_FOR_SALE" (selectionChange)="getServerData()">
                            <mat-option *ngFor="let forSale of ValueOfForSaleArr" [value]="forSale.value">
                                {{forSale.real}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col" *ngIf="forSaleOrLocation == 0">
                <div class="example-container">
                    <mat-form-field>
                        <mat-label>Valor máximo</mat-label>
                        <mat-select formControlName="MAX_VALUE_FOR_SALE" (selectionChange)="getServerData()">
                            <mat-option *ngFor="let forSale of ValueOfForSaleArr" [value]="forSale.value">
                                {{forSale.real}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="col" *ngIf="forSaleOrLocation == 1">
                <div class="example-container">
                    <mat-form-field>
                        <mat-label>Valor mínimo</mat-label>
                        <mat-select formControlName="MIN_VALUE_LOCATION" (selectionChange)="getServerData()">
                            <mat-option *ngFor="let locataion of ValueLocataionArr" [value]="locataion.value">
                                {{locataion.real}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="col" *ngIf="forSaleOrLocation == 1">
                <div class="example-container">
                    <mat-form-field>
                        <mat-label>Valor máximo</mat-label>
                        <mat-select formControlName="MAX_VALUE_LOCATION" (selectionChange)="getServerData()">
                            <mat-option *ngFor="let locataion of ValueLocataionArr" [value]="locataion.value">
                                {{locataion.real}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>


            <div class="col">
                <div class="example-container">
                    <mat-form-field>
                        <mat-label>Tipo</mat-label>
                        <mat-select formControlName="TYPE" (selectionChange)="getServerData()">
                            <mat-option *ngFor="let type of types" [value]="type.tipo_imovel">
                                {{type.tipo_imovel}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col">
                <div class="example-container">
                    <mat-form-field>
                        <mat-label>Quartos</mat-label>
                        <mat-select formControlName="QUARTO" (selectionChange)="getServerData()">
                            <mat-option value="Todos">Todos</mat-option>
                            <mat-option value="1">1</mat-option>
                            <mat-option value="2">2</mat-option>
                            <mat-option value="3">3</mat-option>
                            <mat-option value="4">4</mat-option>
                            <mat-option value="5">5</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col">
                <div class="example-container">
                    <mat-form-field>
                        <mat-label>Vagas de garagem</mat-label>
                        <mat-select formControlName="GARAGEM" (selectionChange)="getServerData()">
                            <mat-option value="Todos">Todos</mat-option>
                            <mat-option value="1">1</mat-option>
                            <mat-option value="2">2</mat-option>
                            <mat-option value="3">3</mat-option>
                            <mat-option value="4">4</mat-option>
                            <mat-option value="5">5</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>

    <hr>

    <div class="container">
        <div class="row" *ngIf="!loading">
            <div class="col-md-4" *ngFor="let dataIm of datasource" style="padding-bottom: 15px;">
                <div class="position-card">
                    <div class="card-container">
                        <div class="card-image" (click)="detalheImovel(dataIm?.id_im)">
                            <img [defaultImage]="defaultImage" [lazyLoad]="dataIm?.nome_foto" class="img">
                        </div>
                        <span class="finalidade">{{dataIm?.finalidade_im}} - #{{dataIm?.chave_im}}</span><br>
                        <span class="finalidade">BAIRRO: {{dataIm?.bairro_im}}</span>
                        <div class="valor" *ngIf="dataIm?.valor == '0.00'">Sob Consulta</div>
                        <div class="valor" *ngIf="dataIm?.valor != '0.00'">{{dataIm?.valor | currency: 'BRL'}}</div>
                        <div class="description">
                            <div *ngIf="dataIm?.carac_moradia != ''">
                                {{dataIm?.carac_moradia}}
                            </div>
                            <div *ngIf="dataIm?.carac_lote != ''">
                                {{dataIm?.carac_lote}}
                            </div>
                            <div *ngIf="dataIm?.carac_rural != ''">
                                {{dataIm?.carac_rural}}
                            </div>
                        </div>

                        <div class="data-bottom-card data-im">
                            <div class="row">
                                <div class="col">
                                    <div class="one-third" *ngIf="dataIm?.quarto && dataIm?.quarto != '0'">
                                        <div class="stat">
                                            <i class="fa fa-bed" aria-hidden="true" matTooltipPosition="above"
                                                matTooltip="Quartos/Suítes"></i>
                                            <span>{{dataIm?.quarto}}</span>
                                        </div>
                                    </div>
                                    <div class="one-third" *ngIf="dataIm?.garagem && dataIm?.garagem != '0'">
                                        <div class="stat">
                                            <i class="fa fa-car" aria-hidden="true" matTooltipPosition="above"
                                                matTooltip="Vagas de Garagem"></i>
                                            <span>{{dataIm?.garagem}}</span>
                                        </div>
                                    </div>
                                    <div class="one-third" *ngIf="dataIm?.area_lote && dataIm?.area_lote != '0'">
                                        <div class="stat">
                                            <i class="fa fa-arrows-alt" aria-hidden="true" matTooltipPosition="above"
                                                matTooltip="Área do Lote"></i>
                                            <span>{{dataIm?.area_lote}} m²</span>
                                        </div>
                                    </div>
                                    <div class="one-third" *ngIf="dataIm?.AREA_TOTAL && dataIm?.AREA_TOTAL != '0'">
                                        <div class="stat">
                                            <i class="fa fa-arrows-alt" aria-hidden="true" matTooltipPosition="above"
                                                matTooltip="Área do Lote"></i>
                                            <span>{{dataIm?.AREA_TOTAL}} m²</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="one-third-2">
                                        <a style="text-decoration: none" class="stat2"
                                            [href]="'/resultado/'+dataIm?.id_im" target="_blank">
                                            <span>Mais detalhes</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <mat-spinner *ngIf="loading"></mat-spinner>
        </div>
        <mat-paginator #paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
            [pageSizeOptions]="[12]" (page)="pageEvent = getServerData($event)" itemsPerPageLabel="Itens por página">
        </mat-paginator>
    </div>
</div>