import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Config } from 'src/app/config';
import { BuscaRapidaService } from './busca-rapida.service';

@Component({
  selector: 'busca-rapida',
  templateUrl: './busca-rapida.component.html',
  styleUrls: ['./busca-rapida.component.scss']
})
export class BuscaRapidaComponent implements OnInit, OnDestroy {

  busca: FormGroup;
  config = new Config();
  types = [];
  cities = [];
  neighborhoods = [];
  ARRAY_LOCATION = [];
  ARRAY_FOR_SALE = [];
  TYPE_IMOB_LOCATION = []
  TYPE_IMOB_FOR_SALE = []
  forSaleOrLocation: number
  searchSubscription;
  searchCitiesSubscription;
  searchTypeSubscription;
  searchNeighborhoodSubscription;
  cidade_principal = this.config.CIDADE_PRINCIPAL

  constructor(private forms: FormBuilder,
    private service: BuscaRapidaService,
    private router: Router,
    private _snackBar: MatSnackBar) { }

  async ngOnInit() {
    await this.createForm();
    await this.getCitiesLocation();
    await this.getCitiesForSale();
    await this.getTypesImob();
    await this.changeForSaleOrLocation('Alugar');
    this.types = this.TYPE_IMOB_LOCATION
  }

  ngOnDestroy() {
    if (this.searchSubscription) this.searchSubscription.unsubscribe();
    if (this.searchCitiesSubscription) this.searchCitiesSubscription.unsubscribe();
    if (this.searchTypeSubscription) this.searchTypeSubscription.unsubscribe();
    if (this.searchNeighborhoodSubscription) this.searchNeighborhoodSubscription.unsubscribe();
  }

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  async buscar() {
    if (this.busca.value.CODIGO != '') {
      this.searchSubscription = this.service.searchByCod(this.busca.value.CODIGO, this.busca.value.FOR_SALE_OR_LOCATION).subscribe((res: any) => {
        if (res.result != false) {
          this.router.navigate([`/resultado/${res.result[0].id_im}`])
        } else {
          let config = new MatSnackBarConfig();
          config.verticalPosition = this.verticalPosition;
          config.horizontalPosition = this.horizontalPosition;
          this._snackBar.open('Não há um imóvel com este código!', 'Ok', config)
        }
      })
    } else {
      this.service.searchValues = this.busca.value;
      this.router.navigate(['/busca'])
    }
  }

  async changeForSaleOrLocation(forSaleOrLocation) {
    this.cities = await [];
    this.types = await [];
    this.neighborhoods = await [];
    if (forSaleOrLocation == 'Comprar') {
      this.forSaleOrLocation = 0
      this.cities = this.ARRAY_FOR_SALE
      this.types = this.TYPE_IMOB_FOR_SALE
      this.busca.patchValue({ 'FOR_SALE_OR_LOCATION': 0 })
      this.busca.patchValue({ 'MIN_VALUE_FOR_SALE': this.ValueOfForSaleArr[0]["value"] })
      this.busca.patchValue({ 'MAX_VALUE_FOR_SALE': this.ValueOfForSaleArr[0]["value"] })
    } else if (forSaleOrLocation == 'Alugar') {
      this.forSaleOrLocation = 1
      this.cities = this.ARRAY_LOCATION
      this.types = this.TYPE_IMOB_LOCATION
      this.busca.patchValue({ 'FOR_SALE_OR_LOCATION': 1 })
      this.busca.patchValue({ 'MIN_VALUE_LOCATION': this.ValueLocataionArr[0]["value"] })
      this.busca.patchValue({ 'MAX_VALUE_LOCATION': this.ValueLocataionArr[0]["value"] })
    }
    await this.busca.patchValue({ 'CITY': this.cidade_principal })
    await this.getNeighborhood(this.cidade_principal)
    await this.busca.patchValue({ 'TYPE': "Todos" })
  }

  async getTypesImob() {
    this.searchTypeSubscription = await this.service.getTypesImob(0)
      .subscribe((res: any) => {
        let cont = 1;
        this.TYPE_IMOB_FOR_SALE[0] = { "tipo_imovel": "Todos" }
        for (let i = 0; i < res.length; i++) {
          if (res[i]["tipo_imovel"] != '') {
            this.TYPE_IMOB_FOR_SALE[cont] = res[i];
            cont++;
          }
        }
      })
    this.searchTypeSubscription = await this.service.getTypesImob(1)
      .subscribe((res: any) => {
        let cont = 1;
        this.TYPE_IMOB_LOCATION[0] = { "tipo_imovel": "Todos" }
        for (let i = 0; i < res.length; i++) {
          if (res[i]["tipo_imovel"] != '') {
            this.TYPE_IMOB_LOCATION[cont] = res[i];
            cont++;
          }
        }
      })
  }

  async getNeighborhood(city) {
    this.neighborhoods = await []
    this.searchNeighborhoodSubscription = await this.service.getNeighborhood(city, this.forSaleOrLocation).subscribe((res: any) => {
      let cont = 1;
      this.neighborhoods[0] = { "bairro_im": "Todos" }
      for (let i = 0; i < res.length; i++) {
        if (res[i]["bairro_im"] != '') {
          this.neighborhoods[cont] = res[i];
          cont++;
        }
      }
    })
    await this.busca.patchValue({ 'NEIGHBORHOOD': "Todos" })
  }

  async getCitiesForSale() {
    this.searchCitiesSubscription = await this.service.getCitiesForSale().subscribe((res: any) => {
      let cont = 0;
      for (let i = 0; i < res.length; i++) {
        if (res[i]["cidade_im"] != '') {
          this.ARRAY_FOR_SALE[cont] = res[i];
          cont++;
        }
      }
    })
  }

  async getCitiesLocation() {
    this.searchCitiesSubscription = await this.service.getCitiesLocation().subscribe((res: any) => {
      let cont = 0;
      for (let i = 0; i < res.length; i++) {
        if (res[i]["cidade_im"] != '') {
          this.ARRAY_LOCATION[cont] = res[i];
          cont++;
        }
      }
    })
  }

  createForm(): void {
    this.busca = this.forms.group({
      'CODIGO': [''],
      'FOR_SALE_OR_LOCATION': [null],
      'TYPE': [null],
      'CITY': [null],
      'NEIGHBORHOOD': [null],
      'MIN_VALUE_FOR_SALE': [null],
      'MAX_VALUE_FOR_SALE': [null],
      'MIN_VALUE_LOCATION': [null],
      'MAX_VALUE_LOCATION': [null],
      'PAGE_INDEX': [0],
      'LIMIT': [0],
    })
  }

  ValueOfForSaleArr: Array<any> = [
    { value: "Todos", real: "Todos" },
    { real: "R$100.000", value: "100000.00" },
    { real: "R$200.000", value: "200000.00" },
    { real: "R$300.000", value: "300000.00" },
    { real: "R$400.000", value: "400000.00" },
    { real: "R$500.000", value: "500000.00" },
    { real: "R$600.000", value: "600000.00" },
    { real: "R$700.000", value: "700000.00" },
    { real: "R$800.000", value: "800000.00" },
    { real: "R$900.000", value: "900000.00" },
    { real: "R$1.000.000", value: "1000000.00" },
    { real: "R$1.200.000", value: "1200000.00" },
    { real: "R$1.400.000", value: "1400000.00" },
    { real: "R$1.600.000", value: "1600000.00" },
    { real: "R$1.800.000", value: "1800000.00" },
    { real: "R$2.000.000", value: "2000000.00" },
    { real: "R$5.000.000", value: "5000000.00" },
    { real: "R$10.000.000", value: "10000000.00" }
  ];

  ValueLocataionArr: Array<any> = [
    { value: "Todos", real: "Todos" },
    { value: "100.00", real: "R$100" },
    { value: "200.00", real: "R$200" },
    { value: "300.00", real: "R$300" },
    { value: "400.00", real: "R$400" },
    { value: "500.00", real: "R$500" },
    { value: "600.00", real: "R$600" },
    { value: "700.00", real: "R$700" },
    { value: "800.00", real: "R$800" },
    { value: "900.00", real: "R$900" },
    { value: "1000.00", real: "R$1.000" },
    { value: "1100.00", real: "R$1.100" },
    { value: "1200.00", real: "R$1.200" },
    { value: "1600.00", real: "R$1.600" },
    { value: "2000.00", real: "R$2.000" },
    { value: "2500.00", real: "R$2.500" },
    { value: "3000.00", real: "R$3.000" },
    { value: "5000.00", real: "R$5.000" },
    { value: "8000.00", real: "R$8.000" },
    { value: "12000.00", real: "R$12.000" },
    { value: "15000.00", real: "R$15.000" },
    { value: "20000.00", real: "R$20.000" },
  ]

}