<div class="container box" style="padding-top: 20px;">
    <mat-card>
        <h4>Login Administrativo</h4>
        <form>
            <mat-form-field class="full-width">
                <input matInput placeholder="Usuário*" [formControl]="user" type="text">
                <mat-error *ngIf="user.hasError('required')">
                    Usuário é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput type="password" placeholder="Senha" [formControl]="pass">
                <mat-error *ngIf="pass.hasError('required')">
                    Senha é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="login()">Login</button>
        </form>
    </mat-card>
</div>