<br>

<div class="container">
    <h2>Cadastro de imóvel</h2>
    <br>
    <div [hidden]="first">
        <div class="row">
            <div class="col">
            </div>
            <div class="col-auto">
                <h5>Cadastro do proprietário</h5>
            </div>
        </div>

        <div class="container card">
            <mat-form-field class="example-full-width">
                <input name="nome" type="text" matInput placeholder="Nome *" [formControl]="NOME">
                <mat-error *ngIf="NOME.hasError('required')">
                    Nome é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input name="email" type="text" matInput placeholder="E-mail *" [formControl]="EMAIL">
                <mat-error *ngIf="EMAIL.hasError('required')">
                    E-mail é <strong>obrigatório</strong>
                </mat-error>
                <mat-error *ngIf="EMAIL.hasError('email')">
                    Insira um e-mail válido
                </mat-error>
            </mat-form-field>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input name="celular" type="text" matInput placeholder="Celular *" [formControl]="CELULAR"
                            mask="(00) 0 0000-0000">
                        <mat-error *ngIf="CELULAR.hasError('required')">
                            Celular é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input name="telefone" type="text" matInput placeholder="Telefone" [formControl]="TELEFONE"
                            mask="(00) 0000-0000">
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col"></div>
                <div class="col-auto">
                    <button mat-raised-button color="primary" (click)="avancar()"
                        [disabled]="EMAIL.hasError('email') || NOME.hasError('required') || CELULAR.hasError('required')">
                        Avançar
                    </button>
                </div>
            </div>
            <br>
        </div>
    </div>

    <div [hidden]="secound">

        <div class="row">
            <div class="col">
                <button mat-raised-button color="primary" (click)="voltar()">
                    Voltar
                </button>
            </div>
            <div class="col-auto">
                <h5>Cadastro do Imóvel</h5>
            </div>
        </div>
        <br>

        <div class="container card">
            <mat-form-field>
                <mat-label>Finalidade</mat-label>
                <mat-select name="finalidade" [formControl]="FINALIDADE">
                    <mat-option value="Residencial">Residencial</mat-option>
                    <mat-option value="Rural">Rural</mat-option>
                    <mat-option value="Comercial">Comercial</mat-option>
                    <mat-option value="Industrial">Industrial</mat-option>
                </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-label>Tipo</mat-label>
                <mat-select name="property_type" [formControl]="TIPO">
                    <mat-option value="Andar_Corporativo">Andar Corporativo</mat-option>
                    <mat-option value="Apartamento">Apartamento</mat-option>
                    <mat-option value="Apartamento_Duplex">Apartamento Duplex</mat-option>
                    <mat-option value="Apartamento_Garden">Apartamento Garden</mat-option>
                    <mat-option value="Apartamento_Triplex">Apartamento Triplex</mat-option>
                    <mat-option value="Area">Área</mat-option>
                    <mat-option value="Bangalo">Bangalô</mat-option>
                    <mat-option value="Barracao">Barracão</mat-option>
                    <mat-option value="Box_Garagem">Box/Garagem</mat-option>
                    <mat-option value="Casa">Casa</mat-option>
                    <mat-option value="Chacara">Chácara</mat-option>
                    <mat-option value="Cobertura">Cobertura</mat-option>
                    <mat-option value="Conjunto">Conjunto</mat-option>
                    <mat-option value="Edicula">Edícula</mat-option>
                    <mat-option value="Fazenda">Fazenda</mat-option>
                    <mat-option value="Flat">Flat</mat-option>
                    <mat-option value="Galpao">Galpão</mat-option>
                    <mat-option value="Haras">Haras</mat-option>
                    <mat-option value="Hotel">Hotel</mat-option>
                    <mat-option value="Ilha">Ilha</mat-option>
                    <mat-option value="Kitnet">Kitnet</mat-option>
                    <mat-option value="Laje">Laje</mat-option>
                    <mat-option value="Loft">Loft</mat-option>
                    <mat-option value="Loja">Loja</mat-option>
                    <mat-option value="Pavilhao">Pavilhão</mat-option>
                    <mat-option value="Penthouse">Penthouse</mat-option>
                    <mat-option value="Ponto">Ponto</mat-option>
                    <mat-option value="Pousada">Pousada</mat-option>
                    <mat-option value="Predio">Prédio</mat-option>
                    <mat-option value="Rancho">Rancho</mat-option>
                    <mat-option value="Resort">Resort</mat-option>
                    <mat-option value="Sala">Sala</mat-option>
                    <mat-option value="Salao">Salão</mat-option>
                    <mat-option value="Sitio">Sítio</mat-option>
                    <mat-option value="Sobrado">Sobrado</mat-option>
                    <mat-option value="Studio">Studio</mat-option>
                    <mat-option value="Terreno">Terreno</mat-option>
                    <mat-option value="Village">Village</mat-option>
                </mat-select>
            </mat-form-field>

            <br>
            <div class="row">
                <div class="col-md-6">
                    <mat-checkbox (click)="Fn_hidden_val_venda()" color="primary">Para Venda</mat-checkbox>
                </div>
                <div class="col-md-6">
                    <mat-checkbox (click)="Fn_hidden_val_locacao()" color="primary">Para Locação</mat-checkbox>
                </div>
                <mat-form-field class="example-full-width" [hidden]="hidden_val_venda">
                    <input name="val_venda" type="text" matInput placeholder="Valor para Venda *"
                        [formControl]="VAL_VENDA">
                </mat-form-field>
                <mat-form-field class="example-full-width" [hidden]="hidden_val_locacao">
                    <input name="val_locacao" type="text" matInput placeholder="Valor para Locação *"
                        [formControl]="VAL_LOCACAO">
                </mat-form-field>
            </div>
            <br>
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="example-full-width">
                        <input type="text" matInput name="nomeCondominio" [formControl]="NOME_CONDOMINIO"
                            placeholder="Nome do condomínio/edifício">
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                        <input type="text" matInput [formControl]="VALOR_CONDOMINIO" placeholder="Valor do condomínio">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                        <mat-label>Aceita financiamento?</mat-label>
                        <mat-select name="financiamento" matInput [formControl]="FINANCIAMENTO">
                            <mat-option value="Sim">Sim</mat-option>
                            <mat-option value="Não">Não</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                        <input type="text" matInput name="endereco" [formControl]="ENDERECO" placeholder="Endereço *">
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input type="text" matInput name="areaTotal" [formControl]="AREA_TOTAL" sufix=" m²"
                            mask="comma_separator.0" placeholder="Área Total">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input type="text" matInput name="areaUtil" [formControl]="AREA_UTIL" placeholder="Área Útil"
                            sufix=" m²" mask="comma_separator.0">
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input type="text" matInput name="quartos" [formControl]="QUARTOS" placeholder="N° Quartos">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input type="text" matInput name="suites" [formControl]="SUITES" placeholder="Suítes">
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input type="text" matInput name="vagasCobertas" [formControl]="VAGAS_COBERTAS"
                            placeholder="Vagas cobertas">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input type="text" matInput name="vagasDescobertas" [formControl]="VAGAS_DESCOBERTAS"
                            placeholder="Vagas Descobertas">
                    </mat-form-field>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <textarea class="form-control rounded-0" name="outrasCaracteristicas" [formControl]="OUTROS"
                            placeholder="Outras características (diferenciais do imóvel)" rows="5"></textarea>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <div class="row">
                <div class="col"></div>
                <div class="col-auto">
                    <button mat-raised-button color="primary" (click)="enviar()"
                        [disabled]="ENDERECO.hasError('required') || sendding">
                        Enviar
                    </button>
                </div>
            </div>
            <br>
        </div>
    </div>
</div>



<br><br>
<br><br>
<br><br>