import { Config } from 'src/app/config';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class BuscaResultadoService {
    config = new Config();
    constructor(private http: HttpClient) { }

    buscar(value) {
        let url = `${this.config.BASE_URL}buscar-im/${this.config.COD_IMOB}`
        return this.http.post(url, value)
    }

}