<br><br>
<form [formGroup]="busca">
    <div class=" text-center">
        <mat-button-toggle-group #group="matButtonToggleGroup" value="Alugar"
            (change)="changeForSaleOrLocation($event.value)">
            <mat-button-toggle value="Comprar">
                <span>Comprar</span>
            </mat-button-toggle>
            <mat-button-toggle value="Alugar">
                <span>Alugar</span>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="example-container">
        <mat-form-field>
            <mat-label>Cidade</mat-label>
            <mat-select [(value)]="cidade_principal" (selectionChange)="getNeighborhood($event.value)"
                formControlName="CITY">
                <mat-option *ngFor="let city of cities" [value]="city.cidade_im">
                    {{city.cidade_im}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="example-container">

        <mat-form-field>
            <mat-label>Bairro</mat-label>
            <mat-select *ngIf="neighborhoods.length != 0" formControlName="NEIGHBORHOOD">
                <mat-option *ngFor="let neighborhood of neighborhoods" [value]="neighborhood.bairro_im">
                    {{neighborhood.bairro_im}}
                </mat-option>
            </mat-select>
            <mat-select *ngIf="neighborhoods.length == 0">
                <mat-option>
                    Carregando...
                </mat-option>
            </mat-select>
        </mat-form-field>

    </div>
    <div class="example-container">
        <mat-form-field>
            <mat-label>Tipo</mat-label>
            <mat-select formControlName="TYPE">
                <mat-option *ngFor="let type of types" [value]="type.tipo_imovel">
                    {{type.tipo_imovel}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="example-container">
        <mat-form-field class="example-full-width">
            <input matInput placeholder="Código" formControlName="CODIGO">
        </mat-form-field>
    </div>

    <div class="example-container">
        <button mat-raised-button color="primary" (click)="buscar()">Buscar</button>
    </div>
</form>