<br>

<div class="container">
    <h2>Área do Cliente</h2>
</div>
<div class="container text-center">
    <div class="row">
        <div *ngIf="URL_LOCADOR != ''" class="col-md-6 icon">
            <p (click)="open(URL_LOCADOR)">
                <img src="assets/icons/checklist.png" class="icon-size"><br>
                Relatórios de vendas
            </p>
        </div>
        <div *ngIf="URL_LOCATARIO != ''" class="col-md-6 icon">
            <p (click)="open(URL_LOCATARIO)">
                <img src="assets/icons/code.png" class="icon-size"><br>
                Segunda Via do Boleto
            </p>
        </div>
    </div>
    <div class="row">
        <div *ngIf="MANUAL_LOCATARIO != ''" class="col-md-6 icon">
            <p (click)="open(MANUAL_LOCATARIO)">
                <img src="assets/icons/book.png" class="icon-size"><br>
                Manual do Locatário
            </p>
        </div>
        <div *ngIf="MANUAL_DESOCUPACAO != ''" class="col-md-6 icon">
            <p (click)="open(MANUAL_DESOCUPACAO)">
                <img src="assets/icons/book.png" class="icon-size"><br>
                Manual de Desocupação
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 icon">
            <p (click)="open('https://wwws.pc.mg.gov.br/atestado/inicial.do?evento=cookie')">
                <img src="assets/icons/clip-outline.png" class="icon-size"><br>
                Antecedentes Criminais
            </p>
        </div>
    </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>