<div class="container box" style="padding-top: 20px;">

    <h4>Administrativo: Banners</h4>

    <div class="row">
        <div class="col"></div>
        <div class="col-auto">
            <button mat-raised-button color="primary" (click)="new()">Adcionar Banner</button>
        </div>
    </div>

    <br>

    <mat-card style="width: 100%; margin: 10px;" *ngFor="let item of listBanners">
        <img [src]="item.url_foto" style="width: 100%;">
        <div class="row">
            <div class="col">
                <p>Link de redirecionamento: <a [href]="item.url" target="_blank">{{item.url}}</a></p>
            </div>
            <div class="col-auto">
                <button mat-raised-button color="primary" (click)="excluir(item)">Excluir Banner</button>
            </div>
        </div>
    </mat-card>

</div>