import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/config';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }
  config = new Config();
  ngOnInit() {
  }
  face = this.config.FACEBOOK
  insta = this.config.INSTAGRAM
  WHATSAPP_NUMBER_1 = this.config.WHATSAPP_NUMBER_1
  WHATSAPP_NUMBER_2 = this.config.WHATSAPP_NUMBER_2
  WHATSAPP_NUMBER_3 = this.config.WHATSAPP_NUMBER_3
  WHATSAPP_NUMBER_4 = this.config.WHATSAPP_NUMBER_4
  DADOS_EMPRESA = this.config.DADOS_EMPRESA

  PHONE_NUMBER_1 = this.config.PHONE_NUMBER_1
  PHONE_NUMBER_2 = this.config.PHONE_NUMBER_2
  EMAIL = this.config.EMAIL_2
  
  open(url) {
    window.open(url, '_blank')
  }
  openWpp(number) {
    window.open(`https://api.whatsapp.com/send?1=pt_BR&phone=55${number.replace(/\D/g, "")}`, '_blank')
  }
}
