<div class="position-card">
    <div class="card-container">
        <div class="card-image" (click)="routerPage(dataIm?.id_im)">
            <img [defaultImage]="defaultImage" [lazyLoad]="dataIm?.nome_foto" class="img">
        </div>
        <span class="finalidade">{{dataIm?.finalidade_im}} - #{{dataIm?.chave_im}}</span><br>
        <span class="finalidade">BAIRRO: {{dataIm?.bairro_im}}</span>
        <div class="valor" *ngIf="dataIm?.valor == '0.00'">Sob Consulta</div>
        <div class="valor" *ngIf="dataIm?.valor != '0.00'">{{dataIm?.valor | currency: 'BRL'}}</div>
        <div class="description">
            <div *ngIf="dataIm?.carac_moradia != ''">
                {{dataIm?.carac_moradia}}
            </div>
            <div *ngIf="dataIm?.carac_lote != ''">
                {{dataIm?.carac_lote}}
            </div>
            <div *ngIf="dataIm?.carac_rural != ''">
                {{dataIm?.carac_rural}}
            </div>
        </div>

        <div class="data-bottom-card data-im">
            <div class="row">
                <div class="col">
                    <div class="one-third" *ngIf="dataIm?.quarto && dataIm?.quarto != '0'">
                        <div class="stat">
                            <i class="fa fa-bed" aria-hidden="true" matTooltipPosition="above"
                                matTooltip="Quartos/Suítes"></i>
                            <span>{{dataIm?.quarto}}</span>
                        </div>
                    </div>
                    <div class="one-third" *ngIf="dataIm?.garagem && dataIm?.garagem != '0'">
                        <div class="stat">
                            <i class="fa fa-car" aria-hidden="true" matTooltipPosition="above"
                                matTooltip="Vagas de Garagem"></i>
                            <span>{{dataIm?.garagem}}</span>
                        </div>
                    </div>
                    <div class="one-third" *ngIf="dataIm?.area_lote && dataIm?.area_lote != '0'">
                        <div class="stat">
                            <i class="fa fa-arrows-alt" aria-hidden="true" matTooltipPosition="above"
                                matTooltip="Área do Lote"></i>
                            <span>{{dataIm?.area_lote}} m²</span>
                        </div>
                    </div>
                    <div class="one-third" *ngIf="dataIm?.AREA_TOTAL && dataIm?.AREA_TOTAL != '0'">
                        <div class="stat">
                            <i class="fa fa-arrows-alt" aria-hidden="true" matTooltipPosition="above"
                                matTooltip="Área do Lote"></i>
                            <span>{{dataIm?.AREA_TOTAL}} m²</span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="one-third-2">
                        <a style="text-decoration: none" class="stat2" [href]="'/resultado/'+dataIm?.id_im"
                            target="_blank">
                            <span>Mais detalhes</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>