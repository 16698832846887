<div *ngFor="let imovel of imovel">
    <div class="container">
        <div class="imagem-fundo">
            <img [defaultImage]="defaultImage" [lazyLoad]="imovel.nome_foto" class="img-large">
        </div>

        <div class="row">
            <div class="col texto-fundo-1">
                <div class="data-box">
                    <h5 *ngIf="imovel.venda_locacao == '1'">Locação</h5>
                    <h5 *ngIf="imovel.venda_locacao == '0'">Venda</h5>
                    <h4 *ngIf="imovel.valor == '0.00'">Sob Consulta</h4>
                    <h4 *ngIf="imovel.valor != '0.00'">{{imovel.valor | currency: 'BRL'}}</h4>
                </div>
            </div>
            <div *ngIf="imovel?.nome_video != '' && imovel?.nome_video != null" class="col-auto texto-fundo-2">
                <div class="highlights" style="border: 4px solid #fff;" (click)="openUrl(imovel?.nome_video)">
                    <img [defaultImage]="defaultImage" [lazyLoad]="imovel.nome_foto" class="img-in-box">
                    <div class="title">
                        <span>Vídeo</span>
                    </div>
                </div>
            </div>
            <div class="col-auto texto-fundo-2">
                <div class="highlights" style="border: 4px solid #fff;" (click)="openGalery()">
                    <img [defaultImage]="defaultImage" [lazyLoad]="imovel.nome_foto" class="img-in-box">
                    <div class="title">
                        <span>Fotos({{sizeGalery}})</span>
                    </div>
                </div>
            </div>

            <!-- <iframe width="420" height="315" [src]="transform()" frameborder="0"
                        allowfullscreen></iframe> -->
        </div>
        <div class="row">
            <div class="col-auto">
                <button mat-icon-button (click)="voltar()">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <h4 *ngIf="imovel.valor == '0.00'">Valor: Sob Consulta</h4>
                <h4 *ngIf="imovel.valor != '0.00'">Valor: {{imovel.valor | currency: 'BRL'}}</h4>
                <h4 *ngIf="imovel.bairro_im != ''">Bairro: {{imovel.bairro_im}}</h4>
            </div>
            <div class="col"></div>
            <div class="col-auto">
                <button mat-raised-button color="primary" (click)="openDialog(imovel.id_im)"
                    style="width: 100%; margin: 5px;">
                    Indicar imóvel
                </button>
                <button mat-raised-button color="success" (click)="contatoWpp()" style="width: 100%;margin: 5px;">
                    Contato WhatsApp
                </button>
            </div>
            <div class="col-auto text-center quadrado">
                <p style="font-size: 90%;padding-top: 10px;">Referência</p>
                <h1>{{imovel.chave_im}}</h1>
            </div>
        </div>
        <br>
        <h3>Características</h3>
        <div class="row">
            <div class="col" *ngIf="imovel?.garagem && imovel?.garagem != '0'">
                <p class="text-center">
                    {{imovel?.garagem}}
                    <mat-icon>directions_car</mat-icon> <br>Vagas
                </p>
            </div>
            <div class="col" *ngIf="imovel?.suite && imovel?.suite != '0'">
                <p class="text-center">
                    {{imovel?.suite}}
                    <mat-icon>meeting_room</mat-icon> <br>Suíte
                </p>
            </div>
            <div class="col" *ngIf="imovel?.quarto && imovel?.quarto != '0'">
                <p class="text-center">
                    {{imovel?.quarto}}
                    <mat-icon>house</mat-icon> <br>Quarto
                </p>
            </div>
            <div class="col" *ngIf="imovel?.sala && imovel?.sala != '0'">
                <p class="text-center">
                    {{imovel?.sala}}
                    <mat-icon>tv</mat-icon> <br>Sala
                </p>
            </div>
            <div class="col" *ngIf="imovel?.cozinha && imovel?.cozinha != '0'">
                <p class="text-center">
                    {{imovel?.cozinha}}
                    <mat-icon>kitchen</mat-icon> <br>Cozinha
                </p>
            </div>
            <div class="col" *ngIf="imovel?.banheiro && imovel?.banheiro != '0'">
                <p class="text-center">
                    {{imovel?.banheiro}}
                    <mat-icon>bathtub</mat-icon> <br>Banheiro
                </p>
            </div>
            <div class="col" *ngIf="imovel?.copa && imovel?.copa != '0'">
                <p class="text-center">
                    {{imovel?.copa}}
                    <mat-icon>room_service</mat-icon> <br>Copa
                </p>
            </div>
            <div class="col" *ngIf="imovel?.varanda && imovel?.varanda != '0'">
                <p class="text-center">
                    {{imovel?.varanda}}
                    <mat-icon>local_florist</mat-icon> <br>Varanda
                </p>
            </div>
            <div class="col" *ngIf="imovel?.quintal && imovel?.quintal != '0'">
                <p class="text-center">
                    {{imovel?.quintal}}
                    <mat-icon>outdoor_grill</mat-icon> <br>Quintal
                </p>
            </div>
            <div class="col" *ngIf="imovel?.area_lote && imovel?.area_lote != '0'">
                <p class="text-center">
                    {{imovel?.area_lote}} m²
                    <mat-icon>zoom_out_map</mat-icon> <br>Área Lote
                </p>
            </div>
            <div class="col" *ngIf="imovel?.AREA_TOTAL && imovel?.AREA_TOTAL != '0'">
                <p class="text-center">
                    {{imovel?.AREA_TOTAL}} m²
                    <mat-icon>zoom_out_map</mat-icon> <br>Área Total
                </p>
            </div>
            <div class="col" *ngIf="imovel?.AREA_PRIVATIVA && imovel?.AREA_PRIVATIVA != '0'">
                <p class="text-center">
                    {{imovel?.AREA_PRIVATIVA}} m²
                    <mat-icon>zoom_out_map</mat-icon> <br>Área Privativa
                </p>
            </div>
        </div>
        <br>
        <hr><br>
        <div class="row">
            <div class="col-md-6">
                <h4>Sobre</h4>
                <p class="text-justify">
                    {{imovel?.carac_moradia | lowercase }}
                    {{imovel?.carac_rural | lowercase }}
                    {{imovel?.carac_lote | lowercase }}
                </p>
                <iframe *ngIf="imovel.lat_im != '' && imovel.lng_im != ''" width="100%" height="300" frameborder="0"
                    scrolling="no" marginheight="0" marginwidth="0"
                    src="https://maps.google.com/maps?q={{imovel.lat_im}},{{imovel.lng_im}}&hl=es&z=14&amp;output=embed">
                </iframe>
                <br />
            </div>
            <div class="col-md-6">
                <app-contato [message]="MENSAGEM"></app-contato>
            </div>
        </div>
    </div>

</div>

<br>
<div class="container">
    <h4>Galeria</h4>
    <ng-image-slider [images]="imageObject" #nav [animationSpeed]="0.2"
        [imageSize]="{width: 300, height: 300, space: 1}">
    </ng-image-slider>
</div>