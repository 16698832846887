import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { MaterialModule } from '../material.module';
import { BuscaRapidaComponent } from './busca/busca-rapida/busca-rapida.component';
import { BuscaResultadoComponent } from './busca/busca-resultado/busca-resultado.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LightboxModule } from 'ngx-lightbox';
import { IndicesEconomicosComponent } from './indices-economicos/indices-economicos.component';
import { ContatoComponent } from './contato/contato.component';

@NgModule({
  declarations: [
    FooterComponent,
    BuscaRapidaComponent,
    BuscaResultadoComponent,
    IndicesEconomicosComponent,
    ContatoComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    LazyLoadImageModule,
    LightboxModule
  ], exports: [
    FooterComponent,
    BuscaRapidaComponent,
    IndicesEconomicosComponent,
    ContatoComponent
  ]
})
export class ComponentsModule { }
