<div class="footer">
    <br>
    <div class="container">
        <div *ngIf="data">
            <h5 class="row">Indices Econômicos</h5>
            <h5 class="row">{{data.MES}}/{{data.ANO}}</h5>
            <div class="row">
                <h5 class="col-auto">
                    IGPM
                    {{data.IGPM}}
                </h5>
                <h5 class="col-auto">
                    IPCA
                    {{data.IPCA}}
                </h5>
                <h5 class="col-auto">
                    INPC
                    {{data.INPC}}
                </h5>
                <h5 class="col-auto">
                    IPC
                    {{data.IPC}}
                </h5>
                <h5 class="col-auto">
                    IGP
                    {{data.IGP}}
                </h5>
                <h5 class="col-auto">
                    POUPANCA
                    {{data.POUPANCA}}
                </h5>
            </div>
        </div>
    </div>
    <br>

</div>