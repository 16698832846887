<div>
    <p>A imagem deve obedecer as proporções de <strong>1024 x 768</strong></p>
    <div *ngIf="uploadResponse && uploadResponse.status === 'error'">
        {{ uploadResponse.message }}
    </div>
    <div *ngIf="uploadResponse && uploadResponse.status === 'success'">
        <img [src]='uploadResponse.url' />
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">

        <input type="file" name="avatar" (change)="onFileSelect($event)" /> <br>
        <mat-form-field style="width: 100%;">
            <mat-label>Link*</mat-label>
            <input matInput formControlName="link">
        </mat-form-field>

        <div class="row">
            <div class="col">
                <button type="button" mat-raised-button (click)="close()">Cancelar</button>
            </div>
            <div class="col">
                <button type="submit" mat-raised-button color="primary">Upload</button>
            </div>
        </div>

    </form>


</div>