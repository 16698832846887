import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { PagesService } from '../pages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from 'src/app/config';
import { Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgImageSliderComponent } from 'ng-image-slider';

@Component({
  selector: 'app-detalhes-imovel',
  templateUrl: './detalhes-imovel.component.html',
  styleUrls: ['./detalhes-imovel.component.scss']
})
export class DetalhesImovelComponent implements OnInit {
  constructor(private service: PagesService,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog, private router: Router) { }
  imageObject: Array<object> = [];
  @ViewChild('nav', { static: false }) slider: NgImageSliderComponent;
  ID_IM
  config = new Config()
  URL_FOTOS = this.config.URL_FOTOS
  defaultImage = this.config.DEFULT_IMAGE
  imovel = []
  imageData = []
  sizeGalery = 0
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  voltar() {
    this.router.navigateByUrl('/busca')
  }

  async ngOnInit() {
    let ID_IM = await this.activatedRoute.snapshot.params.id_im
    this.ID_IM = ID_IM
    await this.service.getDatailsImovel(ID_IM).toPromise()
      .then(async (response: any) => {
        response[0].nome_foto = this.config.URL_FOTOS_NO_THUMB.concat(response[0].nome_foto)
        this.imageData[0] = { id: 0, url: response[0].nome_foto }
        this.imovel = response
        await this.setMessageContato(response[0])
      })

    await this.getGalery(this.ID_IM)
    window.scrollTo(0, 0)
  }

  async getGalery(ID_IM) {
    await this.service.getimagensOfImovel(ID_IM)
      .subscribe((res: any) => {
        if (res != 'nada') {
          this.sizeGalery = res.length
          for (let i = 0; i < res.length; i++) {
            res[i].nome_foto = this.config.URL_FOTOS.concat(res[i].nome_foto)
            this.imageObject[i] = { image: res[i].nome_foto, thumbImage: res[i].nome_foto }
            if (res.length != i - 1) {
              this.imageData[i + 1] = { id: i, url: res[i].nome_foto }
            }
          }
        }
      })
  }

  openGalery() {
    this.slider.imageOnClick(0);
  }

  contatoWpp() {
    let url = `https://api.whatsapp.com/send?1=pt_BR&phone=55${this.config.WHATSAPP_NUMBER_1}&text=${this.MENSAGEM}`
    open(url, '_blank')
  }


  MENSAGEM: string
  setMessageContato(res) {
    this.MENSAGEM = `Olá! Gostaria de receber mais informações sobre este imóvel: ${res.cidade_im}, ${res.bairro_im}, chave: ${res.chave_im}, que encontrei na Imobiliária. Aguardo seu contato. Obrigado(a).`
  }

  async openDialog(id_im) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = 3000;

    const dialogRef = this.dialog.open(DialogIndicarImovel, {
      width: '450px',
      data: { id_im: id_im }
    });

    await dialogRef.afterClosed().toPromise().then(result => {
      if (result != undefined)
        if (result.cod == 1)
          this._snackBar.open('E-mail enviado com sucesso!', 'Ok', config)
    });
  }

  prevImageClick() {
    this.slider.prev();
  }

  nextImageClick() {
    this.slider.next();
  }

  openUrl(url) {
    window.open(url, '_blank')
  }

}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'dialog-indicar',
  templateUrl: 'dialog-indicar.html',
  styleUrls: ['./detalhes-imovel.component.scss']
})
export class DialogIndicarImovel {

  constructor(
    public dialogRef: MatDialogRef<DialogIndicarImovel>, private service: PagesService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  enviando = false
  config = new Config()
  message = `Olá, gostaria de te indicar um imóvel da imobiliária ${this.config.NAME_IMOB}.`
    .concat(`O link do imóvel é ${this.config.IMOB_LINK_BASE}/resultado/${this.data.id_im}`)
  NOME = new FormControl(null, [Validators.required]);
  NOME_AMIGO = new FormControl(null, [Validators.required]);
  EMAIL = new FormControl(null, [Validators.required, Validators.email]);
  EMAIL_AMIGO = new FormControl(null, [Validators.required, Validators.email]);
  MENSAGEM = new FormControl(this.message, [Validators.required]);
  NOME_IMOB = new FormControl(null);
  EMAIL_IMOB = new FormControl(null);
  COLOR = new FormControl(null);

  onNoClick(): void {
    this.dialogRef.close();
  }

  async contato() {
    this.enviando = true
    let contato = {
      'NOME': this.NOME.value,
      'NOME_AMIGO': this.NOME_AMIGO.value,
      'EMAIL': this.EMAIL.value,
      'EMAIL_AMIGO': this.EMAIL_AMIGO.value,
      'MENSAGEM': this.MENSAGEM.value,
      'NOME_IMOB': this.config.NAME_IMOB,
      'EMAIL_IMOB': this.config.EMAIL_1,
      'COLOR': this.config.EMAIL_COLOR,
    }

    await this.service.sendEmailIndicarImovel(contato).toPromise().then(async (res: any) => {
      if (res.cod == 1)
        this.dialogRef.close(res);
      else
        this.enviando = false
    })
  }

}