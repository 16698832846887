<div class="container">
    <br>
    <app-contato></app-contato>
    <br>
    <mat-card>
        <div class="row">
            <div class="col-md-6">
                <h4>Dados</h4>
                <hr>
                <p>Endereço</p>
                <h5>{{config.DADOS_EMPRESA.CIDADE}}</h5>
                <h5>{{config.DADOS_EMPRESA.BAIRRO}}</h5>
                <h5>{{config.DADOS_EMPRESA.CEP}}</h5>
                <h5>{{config.DADOS_EMPRESA.RUA}}</h5>
            </div>
            <div class="col-md-6">
                <h4>Contato</h4>
                <hr>
                <p>WhatsApp</p>
                <h5 (click)="open(config.WHATSAPP_NUMBER_1)" class="whats">{{config.WHATSAPP_NUMBER_1}}
                </h5>

                <p>Telefone</p>
                <h5>{{config.PHONE_NUMBER_1}}</h5>

                <p>E-mail</p>
                <h5>{{config.EMAIL_1}}</h5>
                <h5>{{config.EMAIL_2}}</h5>
                <h5>{{config.EMAIL_3}}</h5>
                <h5>{{config.EMAIL_4}}</h5>
            </div>
        </div>
    </mat-card>

</div>
<br>