import { Component, OnInit } from '@angular/core';
import { IndicesEconomicosService } from './indices-economicos.service';

@Component({
  selector: 'app-indices-economicos',
  templateUrl: './indices-economicos.component.html',
  styleUrls: ['./indices-economicos.component.scss']
})
export class IndicesEconomicosComponent implements OnInit {

  constructor(private service: IndicesEconomicosService) { }
  data
  async ngOnInit() {
    await this.service.getIndicesEconomicos().subscribe(async (res) => {
      if (res[0].MES == '1') res[0].MES = 'Janeiro'
      else if (res[0].MES == '2') res[0].MES = 'Fevereiro'
      else if (res[0].MES == '3') res[0].MES = 'Março'
      else if (res[0].MES == '4') res[0].MES = 'Abril'
      else if (res[0].MES == '5') res[0].MES = 'Maio'
      else if (res[0].MES == '6') res[0].MES = 'Junho'
      else if (res[0].MES == '7') res[0].MES = 'Julho'
      else if (res[0].MES == '8') res[0].MES = 'Agosto'
      else if (res[0].MES == '9') res[0].MES = 'Setembro'
      else if (res[0].MES == '10') res[0].MES = 'Outubro'
      else if (res[0].MES == '11') res[0].MES = 'Novembro'
      else if (res[0].MES == '12') res[0].MES = 'Dezembro'

      this.data = await res[0]

    })
  }

}
