import { ComponentsModule } from './../components/components.module';
import { MaterialModule } from './../material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgImageSliderModule } from 'ng-image-slider';

import { DetalhesImovelComponent, DialogIndicarImovel } from './detalhes-imovel/detalhes-imovel.component';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { GallerizeModule } from '@ngx-gallery/gallerize';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CarouselModule } from 'ngx-bootstrap';
import { NguCarouselModule } from '@ngu/carousel';
import { FaleConoscoComponent } from './fale-conosco/fale-conosco.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SobreEmpresaComponent } from './sobre-empresa/sobre-empresa.component';
import { AgenteImobiliarioComponent } from './agente-imobiliario/agente-imobiliario.component';
import { AreaClienteComponent } from './area-cliente/area-cliente.component';
import { CadastreImovelComponent } from './cadastre-imovel/cadastre-imovel.component';
import { SimuladoresComponent } from './simuladores/simuladores.component';
import { AdmComponent, DialogNewBanner } from './sobre-empresa/adm/adm.component';
import { AdmLoginComponent } from './sobre-empresa/adm-login/adm-login.component';
import { TrabalheConoscoComponent } from './trabalhe-conosco/trabalhe-conosco.component';

@NgModule({
  declarations: [ 
    DetalhesImovelComponent,
    FaleConoscoComponent,
    SobreEmpresaComponent,
    AgenteImobiliarioComponent,
    AreaClienteComponent,
    CadastreImovelComponent,
    SimuladoresComponent,
    AdmComponent,
    DialogNewBanner,
    AdmLoginComponent,
    DialogIndicarImovel,
    TrabalheConoscoComponent

  ],
  imports: [
    CommonModule,
    NgbModule,
    MaterialModule,
    ComponentsModule,
    GalleryModule,
    LightboxModule.withConfig({
      panelClass: 'fullscreen'
    }),
    LazyLoadImageModule,
    GallerizeModule,
    CarouselModule,
    NguCarouselModule,
    NgImageSliderModule,
    NgxSkeletonLoaderModule,
  ], entryComponents: [
    DialogNewBanner,
    DialogIndicarImovel
  ]
})
export class PagesModule { }
