<h1 mat-dialog-title>Indicar imóvel</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col-md-6">
            <mat-form-field class="example-full-width">
                <input matInput [formControl]="NOME"  placeholder="Seu Nome*">
                <mat-error *ngIf="NOME.hasError('required')">
                    Seu nome é <strong>obrigatório </strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="example-full-width">
                <input matInput [formControl]="NOME_AMIGO" 
                    placeholder="Nome do deu amigo*">
                <mat-error *ngIf="NOME_AMIGO.hasError('required')">
                    Nome do seu amigo é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <mat-form-field class="example-full-width">
                <input matInput [formControl]="EMAIL"  placeholder="Seu E-mail*">
                <mat-error *ngIf="EMAIL.hasError('required')">
                    Seu e-mail é <strong>obrigatório</strong>
                </mat-error>
                <mat-error *ngIf="EMAIL.hasError('email')">
                    Seu e-mail está <strong>incorreto</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="example-full-width">
                <input matInput [formControl]="EMAIL_AMIGO" 
                    placeholder="E-mail do seu amigo*">
                <mat-error *ngIf="EMAIL_AMIGO.hasError('required')">
                    E-mail do é <strong>obrigatório</strong>
                </mat-error>
                <mat-error *ngIf="EMAIL_AMIGO.hasError('email')">
                    E-mail do amigo está <strong>incorreto</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <mat-form-field class="example-full-width">
                <textarea matInput placeholder="Mensagem*" [formControl]="MENSAGEM"></textarea>
                <mat-error *ngIf="MENSAGEM.hasError('required')">
                    Mensagem é <strong>obrigatória</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" [disabled]="enviando">Cancelar</button>
    <button mat-raised-button color="primary" (click)="contato()"
        [disabled]="EMAIL.hasError('email') || EMAIL.hasError('required') || 
EMAIL_AMIGO.hasError('email') || EMAIL_AMIGO.hasError('required') || NOME.hasError('required') || NOME_AMIGO.hasError('required') || MENSAGEM.hasError('required') || enviando">
        Enviar
    </button>
</div>