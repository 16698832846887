import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatIconModule, MatListModule, MatPaginatorIntl, MatSidenavModule, MatToolbarModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NguCarouselModule } from '@ngu/carousel';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CarouselModule } from 'ngx-bootstrap';
import { LightboxModule } from 'ngx-lightbox';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomPaginator } from './components/busca/busca-resultado/CustomPaginatorConfiguration';
import { CardImovelComponent } from './components/card-imovel/card-imovel.component';
import { ComponentsModule } from './components/components.module';
import { MaterialModule } from './material.module';
import { IndexComponent } from './pages/index/index.component';
import { PagesModule } from './pages/pages.module';

registerLocaleData(ptBr)

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    CardImovelComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ComponentsModule,
    NgbModule,
    MaterialModule,
    FlexLayoutModule,
    LightboxModule,
    LazyLoadImageModule,
    CarouselModule,
    NguCarouselModule,
    NgxSkeletonLoaderModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    PagesModule,
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
  ]
})
export class AppModule { }
