import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'src/app/config';

@Injectable({
  providedIn: 'root'
})

export class BuscaRapidaService {
  config = new Config();
  constructor(private http: HttpClient) { }

  getCitiesLocation() {
    let url = `${this.config.BASE_URL}listar/cidade/${this.config.COD_IMOB}/1/N`
    return this.http.get(url)
  }

  getCitiesForSale() {
    let url = `${this.config.BASE_URL}listar/cidade/${this.config.COD_IMOB}/0/N`
    return this.http.get(url)
  }

  getNeighborhood(city, forSaleOrLocation) {
    let url = `${this.config.BASE_URL}listar/bairro/${this.config.COD_IMOB}/${forSaleOrLocation}/${city}`
    return this.http.get(url)
  }

  getTypesImob(comprarAlugar) {
    let url = `${this.config.BASE_URL}listar/tipos/${this.config.COD_IMOB}/${comprarAlugar}/N`
    return this.http.get(url)
  }

  searchByCod(cod, vendaLocacao) {
    let url = `${this.config.BASE_URL}busca-codigo-pedrao/${this.config.COD_IMOB}/${cod}/${vendaLocacao}`
    return this.http.get(url)
  }

  _searchValues: any;

  set searchValues(value: any) {
    this._searchValues = value;
  }

  get searchValues(): any {
    return this._searchValues;
  }

}
