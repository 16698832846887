export class Config {
    constructor() { }

    readonly NAME_IMOB = 'Oriente Administradora'
    readonly IMOB_LINK_BASE = 'http://orienteadministradora.com.br/'
    readonly BASE_URL = 'https://waysoft.net.br/apiapp/API-IMOB/api/';
    readonly COD_IMOB: string = '8ba76bee45d80a448b0ba6eeb9b09b71';
    readonly BASE_URL_UPLOAD_BANNER = 'http://orienteadministradora.com.br/banner/upload_banner.php'
    readonly BASE_URL_UPLOAD_CV = 'http://orienteadministradora.com.br/trabalhe_conosco/upload_cv.php'
    readonly CONTACT_URL: string = 'http://waysoft.com.br/API/imob_contatos/';
    readonly VERSION = '2.0.0';
    readonly DEFULT_IMAGE = 'assets/img/defult_img.png'
    readonly WHATSAPP_NUMBER_1 = '(35) 9 8826-7227'
    readonly WHATSAPP_NUMBER_2 = ''
    readonly WHATSAPP_NUMBER_3 = ''
    readonly WHATSAPP_NUMBER_4 = ''

    readonly PHONE_NUMBER_1 = '(35)3471-3581'
    readonly PHONE_NUMBER_2 = ''
    readonly PHONE_NUMBER_3 = ''
    readonly PHONE_NUMBER_4 = ''

    readonly URL_LOCADOR = ''
    readonly URL_LOCATARIO = ''
    readonly MANUAL_LOCATARIO = ''
    readonly MANUAL_DESOCUPACAO = ''

    readonly DADOS_EMPRESA = {
        'CIDADE': 'Santa Rita do Sapucaí - MG',
        'BAIRRO': 'Bairro Centro',
        'CEP': '37540-000',
        'RUA': 'Rua Coronel Erasmo Cabral n° 217'
    }
    readonly CIDADE_PRINCIPAL = 'SANTA RITA DO SAPUCAÍ'
    readonly URL_FOTOS = 'http://orienteadministradora.com.br/thumb.php?mv=centro&mh=meio&img=fotos/'
    readonly URL_FOTOS_NO_THUMB = 'http://orienteadministradora.com.br/fotos/'
    readonly URL_BANNER = 'http://orienteadministradora.com.br/banner/'

    readonly EMAIL_1 = 'orienteimoveis@hotmail.com'
    readonly EMAIL_2 = ''
    readonly EMAIL_3 = ''
    readonly EMAIL_4 = ''
    readonly EMAIL_COLOR = '#00A551'

    readonly INSTAGRAM = '';
    readonly FACEBOOK = '';
}


