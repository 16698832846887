import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-adm-login',
  templateUrl: './adm-login.component.html',
  styleUrls: ['./adm-login.component.scss']
})
export class AdmLoginComponent {

  constructor(private service: PagesService) { }
  user = new FormControl(null, [Validators.required]);
  pass = new FormControl(null, [Validators.required]);
  login$: Subscription
  hide = true;
  async login() {
    if (this.user.valid && this.pass.valid) {
      this.service.loginAdm(this.user.value, this.pass.value)
    }

  }


  ngOnDestroy(): void {
    if (this.login$) this.login$.unsubscribe()
  }

}
