import { Config } from 'src/app/config';
import { Router } from '@angular/router';
import { BuscaRapidaService } from './../busca-rapida/busca-rapida.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BuscaResultadoService } from './busca-resultado.service';
import { PageEvent } from '@angular/material';
@Component({
  selector: 'busca-resultado',
  templateUrl: './busca-resultado.component.html',
  styleUrls: ['./busca-resultado.component.scss']
})

export class BuscaResultadoComponent implements OnInit {
  busca: FormGroup;
  config = new Config();
  toggleForSaleOrLocation = ''
  pageEvent;
  pageIndex
  pageSize
  types = [];
  cities = [];
  neighborhoods = [];
  ARRAY_LOCATION = [];
  ARRAY_FOR_SALE = [];
  TYPE_IMOB_LOCATION = []
  TYPE_IMOB_FOR_SALE = []
  forSaleOrLocation: number;
  defaultImage = this.config.DEFULT_IMAGE
  datasource = []
  length = '0'
  STATIC_RESULT_SEARCH
  loading = false
  cidade_principal = this.config.CIDADE_PRINCIPAL

  async ngOnInit() {
    let checkedValues = this.service.searchValues;
    await this.getNeighborhood(checkedValues.CITY)
    await this.busca.patchValue({ 'CITY': checkedValues.CITY })
    await this.busca.patchValue({ 'NEIGHBORHOOD': checkedValues.NEIGHBORHOOD })
    await this.busca.patchValue({ 'TYPE': checkedValues.TYPE })
    await this.busca.patchValue({ 'PAGE_INDEX': checkedValues.PAGE_INDEX })
    this.pageIndex = checkedValues.PAGE_INDEX
    if (checkedValues.LIMIT == undefined)
      await this.busca.patchValue({ 'LIMIT': 0 })
    else
      await this.busca.patchValue({ 'LIMIT': checkedValues.LIMIT })
    this.getServerData(null)
  }

  public async getServerData(event?: PageEvent) {
    if (event != null) {
      await this.busca.patchValue({ 'PAGE_INDEX': event.pageIndex })
      let indexPage = (event.pageIndex) * 12
      this.busca.patchValue({ 'LIMIT': indexPage })
    }
    this.loading = true
    await this.buscarResultadoService
      .buscar(this.busca.value)
      .subscribe(async (response: any) => {
        this.length = response.totalItens;
        let res = response.data
        for (let i = 0; i < res.length; i++) {
          res[i].nome_foto = this.config.URL_FOTOS.concat(res[i].nome_foto)
          if (res[i].suite != 0)
            res[i].quarto = parseInt(res[i].quarto) + parseInt(res[i].suite)
        }
        this.datasource = res
        this.loading = false
      }, err => {
        this.loading = false
        console.log(err)
      }
      )
  }

  async changeForSaleOrLocation(forSaleOrLocation) {
    this.cities = await [];
    this.types = await [];
    this.neighborhoods = await [];
    if (forSaleOrLocation == 'Comprar') {
      this.forSaleOrLocation = 0
      this.cities = this.ARRAY_FOR_SALE
      this.types = this.TYPE_IMOB_FOR_SALE
      this.busca.patchValue({ 'FOR_SALE_OR_LOCATION': 0 })
      this.busca.patchValue({ 'MIN_VALUE_FOR_SALE': this.ValueOfForSaleArr[0]["value"] })
      this.busca.patchValue({ 'MAX_VALUE_FOR_SALE': this.ValueOfForSaleArr[0]["value"] })
    } else if (forSaleOrLocation == 'Alugar') {
      this.forSaleOrLocation = 1
      this.cities = this.ARRAY_LOCATION
      this.types = this.TYPE_IMOB_LOCATION
      this.busca.patchValue({ 'FOR_SALE_OR_LOCATION': 1 })
      this.busca.patchValue({ 'MIN_VALUE_LOCATION': this.ValueLocataionArr[0]["value"] })
      this.busca.patchValue({ 'MAX_VALUE_LOCATION': this.ValueLocataionArr[0]["value"] })
    }
    await this.busca.patchValue({ 'CITY': this.cidade_principal })
    await this.getNeighborhood(this.cidade_principal)
    await this.busca.patchValue({ 'TYPE': "Todos" })

    this.getServerData(null)
  }

  async getTypesImob() {
    await this.service.getTypesImob(0)
      .subscribe((res: any) => {
        let cont = 1;
        this.TYPE_IMOB_FOR_SALE[0] = { "tipo_imovel": "Todos" }
        for (let i = 0; i < res.length; i++) {
          if (res[i]["tipo_imovel"] != '') {
            this.TYPE_IMOB_FOR_SALE[cont] = res[i];
            cont++;
          }
        }
      })
    await this.service.getTypesImob(1)
      .subscribe((res: any) => {
        let cont = 1;
        this.TYPE_IMOB_LOCATION[0] = { "tipo_imovel": "Todos" }
        for (let i = 0; i < res.length; i++) {
          if (res[i]["tipo_imovel"] != '') {
            this.TYPE_IMOB_LOCATION[cont] = res[i];
            cont++;
          }
        }
      })
  }

  async getNeighborhood(city) {
    this.neighborhoods = await []
    await this.service.getNeighborhood(city, this.forSaleOrLocation).subscribe((res: any) => {
      let cont = 1;
      this.neighborhoods[0] = { "bairro_im": "Todos" }
      for (let i = 0; i < res.length; i++) {
        if (res[i]["bairro_im"] != '') {
          this.neighborhoods[cont] = res[i];
          cont++;
        }
      }
    })
    await this.busca.patchValue({ 'NEIGHBORHOOD': "Todos" })
  }

  async getCitiesForSale() {
    await this.service.getCitiesForSale().subscribe((res: any) => {
      let cont = 0;
      for (let i = 0; i < res.length; i++) {
        if (res[i]["cidade_im"] != '') {
          this.ARRAY_FOR_SALE[cont] = res[i];
          cont++;
        }
      }
    })
  }

  async getCitiesLocation() {
    await this.service.getCitiesLocation().subscribe((res: any) => {
      let cont = 0;
      for (let i = 0; i < res.length; i++) {
        if (res[i]["cidade_im"] != '') {
          this.ARRAY_LOCATION[cont] = res[i];
          cont++;
        }
      }
    })
  }

  detalheImovel(id_im) {
    this.service.searchValues = this.busca.value
    this.router.navigateByUrl(`/resultado/${id_im}`)
  }

  createForm(): void {
    this.busca = this.forms.group({
      'FOR_SALE_OR_LOCATION': [null],
      'TYPE': [null],
      'CITY': [null],
      'NEIGHBORHOOD': [null],
      'QUARTO': ["Todos"],
      'GARAGEM': ["Todos"],
      'MIN_VALUE_FOR_SALE': [null],
      'MAX_VALUE_FOR_SALE': [null],
      'MIN_VALUE_LOCATION': [null],
      'MAX_VALUE_LOCATION': [null],
      'LIMIT': [0],
      'PAGE_INDEX': [0],
    })
  }

  ValueOfForSaleArr: Array<any> = [
    { value: "Todos", real: "Todos" },
    { real: "R$100.000", value: "100000.00" },
    { real: "R$200.000", value: "200000.00" },
    { real: "R$300.000", value: "300000.00" },
    { real: "R$400.000", value: "400000.00" },
    { real: "R$500.000", value: "500000.00" },
    { real: "R$600.000", value: "600000.00" },
    { real: "R$700.000", value: "700000.00" },
    { real: "R$800.000", value: "800000.00" },
    { real: "R$900.000", value: "900000.00" },
    { real: "R$1.000.000", value: "1000000.00" },
    { real: "R$1.200.000", value: "1200000.00" },
    { real: "R$1.400.000", value: "1400000.00" },
    { real: "R$1.600.000", value: "1600000.00" },
    { real: "R$1.800.000", value: "1800000.00" },
    { real: "R$2.000.000", value: "2000000.00" },
    { real: "R$5.000.000", value: "5000000.00" },
    { real: "R$10.000.000", value: "10000000.00" }
  ];

  ValueLocataionArr: Array<any> = [
    { value: "Todos", real: "Todos" },
    { value: "100.00", real: "R$100" },
    { value: "200.00", real: "R$200" },
    { value: "300.00", real: "R$300" },
    { value: "400.00", real: "R$400" },
    { value: "500.00", real: "R$500" },
    { value: "600.00", real: "R$600" },
    { value: "700.00", real: "R$700" },
    { value: "800.00", real: "R$800" },
    { value: "900.00", real: "R$900" },
    { value: "1000.00", real: "R$1.000" },
    { value: "1100.00", real: "R$1.100" },
    { value: "1200.00", real: "R$1.200" },
    { value: "1600.00", real: "R$1.600" },
    { value: "2000.00", real: "R$2.000" },
    { value: "2500.00", real: "R$2.500" },
    { value: "3000.00", real: "R$3.000" },
    { value: "5000.00", real: "R$5.000" },
    { value: "8000.00", real: "R$8.000" },
    { value: "12000.00", real: "R$12.000" },
    { value: "15000.00", real: "R$15.000" },
    { value: "20000.00", real: "R$20.000" },
  ]

  constructor(private forms: FormBuilder,
    private service: BuscaRapidaService,
    private buscarResultadoService: BuscaResultadoService,
    private router: Router) {
    if (this.service.searchValues == undefined) this.router.navigateByUrl("/");
    this.createForm();
    this.getCitiesLocation();
    this.getCitiesForSale();
    this.getTypesImob();
    this.busca.patchValue(this.service.searchValues)
    let checkedValues = this.service.searchValues;
    if (checkedValues.FOR_SALE_OR_LOCATION == 0) {
      this.toggleForSaleOrLocation = 'Comprar'
      this.forSaleOrLocation = 0
      this.cities = this.ARRAY_FOR_SALE
      this.types = this.TYPE_IMOB_FOR_SALE
      this.busca.patchValue({ 'FOR_SALE_OR_LOCATION': 0 })
      this.busca.patchValue({ 'MIN_VALUE_FOR_SALE': checkedValues.MIN_VALUE_FOR_SALE })
      this.busca.patchValue({ 'MAX_VALUE_FOR_SALE': checkedValues.MAX_VALUE_FOR_SALE })
    } else {
      this.toggleForSaleOrLocation = 'Alugar'
      this.forSaleOrLocation = 1
      this.cities = this.ARRAY_LOCATION
      this.types = this.TYPE_IMOB_LOCATION
      this.busca.patchValue({ 'FOR_SALE_OR_LOCATION': 1 })
      this.busca.patchValue({ 'MIN_VALUE_LOCATION': checkedValues.MIN_VALUE_LOCATION })
      this.busca.patchValue({ 'MAX_VALUE_LOCATION': checkedValues.MAX_VALUE_LOCATION })
    }
  }


}
