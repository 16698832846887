import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Config } from 'src/app/config';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-cadastre-imovel',
  templateUrl: './cadastre-imovel.component.html',
  styleUrls: ['./cadastre-imovel.component.scss']
})
export class CadastreImovelComponent implements OnInit {

  constructor(private service: PagesService,
    private _snackBar: MatSnackBar) { }
  first = false
  sendding = false
  secound = true
  hidden_val_venda = true
  hidden_val_locacao = true
  config = new Config();
  NOME = new FormControl(null, [Validators.required]);
  CELULAR = new FormControl(null, [Validators.required, Validators.minLength(11)]);
  TELEFONE = new FormControl(null, [Validators.required, Validators.minLength(10)]);
  EMAIL = new FormControl(null, [Validators.required]);
  VAL_VENDA = new FormControl(null);
  VAL_LOCACAO = new FormControl(null);
  FINALIDADE = new FormControl(null);
  TIPO = new FormControl(null);
  NOME_CONDOMINIO = new FormControl(null);
  VALOR_CONDOMINIO = new FormControl(null);
  FINANCIAMENTO = new FormControl(null);
  ENDERECO = new FormControl(null, [Validators.required]);
  AREA_TOTAL = new FormControl(null);
  AREA_UTIL = new FormControl(null);
  QUARTOS = new FormControl(null);
  SUITES = new FormControl(null);
  VAGAS_COBERTAS = new FormControl(null);
  VAGAS_DESCOBERTAS = new FormControl(null);
  OUTROS = new FormControl(null);
  NAME_IMOB = this.config.NAME_IMOB
  EMAIL_IMOB = this.config.EMAIL_1

  voltar() {
    this.first = false
    this.secound = true
  }

  avancar() {
    this.first = true
    this.secound = false
  }

  Fn_hidden_val_venda() {
    if (this.hidden_val_venda) this.hidden_val_venda = false
    else this.hidden_val_venda = true
  }

  Fn_hidden_val_locacao() {
    if (this.hidden_val_locacao) this.hidden_val_locacao = false
    else this.hidden_val_locacao = true
  }
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  async enviar() {
    this.sendding = true
    let value = {
      NOME: this.NOME.value,
      CELULAR: this.CELULAR.value,
      TELEFONE: this.TELEFONE.value,
      EMAIL: this.EMAIL.value,
      VAL_VENDA: this.VAL_VENDA.value,
      VAL_LOCACAO: this.VAL_LOCACAO.value,
      FINALIDADE: this.FINALIDADE.value,
      TIPO: this.TIPO.value,
      NOME_CONDOMINIO: this.NOME_CONDOMINIO.value,
      VALOR_CONDOMINIO: this.VALOR_CONDOMINIO.value,
      FINANCIAMENTO: this.FINANCIAMENTO.value,
      ENDERECO: this.ENDERECO.value,
      AREA_TOTAL: this.AREA_TOTAL.value,
      AREA_UTIL: this.AREA_UTIL.value,
      QUARTOS: this.QUARTOS.value,
      SUITES: this.SUITES.value,
      VAGAS_COBERTAS: this.VAGAS_COBERTAS.value,
      VAGAS_DESCOBERTAS: this.VAGAS_DESCOBERTAS.value,
      OUTROS: this.OUTROS.value,
      NAME_IMOB: this.NAME_IMOB,
      EMAIL_IMOB: this.EMAIL_IMOB,
    }
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = 3000;
    await this.service.sendEmailCadastroImovel(value).subscribe((res: any) => {
      this._snackBar.open(res.message, 'Ok', config)
      if (res.cod == 1) {
        this.NOME.setValue(null)
        this.CELULAR.setValue(null)
        this.TELEFONE.setValue(null)
        this.EMAIL.setValue(null)
        this.VAL_VENDA.setValue(null)
        this.VAL_LOCACAO.setValue(null)
        this.FINALIDADE.setValue(null)
        this.TIPO.setValue(null)
        this.NOME_CONDOMINIO.setValue(null)
        this.VALOR_CONDOMINIO.setValue(null)
        this.FINANCIAMENTO.setValue(null)
        this.ENDERECO.setValue(null)
        this.AREA_TOTAL.setValue(null)
        this.AREA_UTIL.setValue(null)
        this.QUARTOS.setValue(null)
        this.SUITES.setValue(null)
        this.VAGAS_COBERTAS.setValue(null)
        this.VAGAS_DESCOBERTAS.setValue(null)
        this.OUTROS.setValue(null)
        this.sendding = false
        this.voltar()
      }
    }, err => {
      console.log(err)
      this._snackBar.open(err.message, 'Ok', config)
      this.sendding = false

    })
  }

  ngOnInit() {
  }

}
