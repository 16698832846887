import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/config';

@Component({
  selector: 'app-area-cliente',
  templateUrl: './area-cliente.component.html',
  styleUrls: ['./area-cliente.component.scss']
})
export class AreaClienteComponent implements OnInit {
  config = new Config();
  constructor() { }

  ngOnInit() {
  }
  URL_LOCADOR = this.config.URL_LOCADOR
  URL_LOCATARIO = this.config.URL_LOCATARIO
  MANUAL_LOCATARIO = this.config.MANUAL_LOCATARIO
  MANUAL_DESOCUPACAO = this.config.MANUAL_DESOCUPACAO
  open(url) {
    window.open(url, '_blank')
  }

}
