import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBarHorizontalPosition, MatSnackBarConfig, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { Config } from 'src/app/config';
import { Subscription } from 'rxjs';
import { ContatoService } from './contato.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {
  sendding = true
  @Input() message;
  constructor(private service: ContatoService,
    private _snackBar: MatSnackBar, private router: Router) { }
  curretRoute = this.router.url
  ngOnInit() {
    if (this.message)
      this.MENSAGEM.setValue(this.message)
  }
  options: string[] = ['Apartamento', 'Casa', 'Comercial', 'Hotel', 'Galpão', 'Loteamento', 'Terreno', 'Terreno Rural'];
  config = new Config()
  NOME = new FormControl(null, [Validators.required]);
  EMAIL = new FormControl(null, [Validators.email]);
  TELEFONE = new FormControl(null, [Validators.required]);
  MENSAGEM = new FormControl(null, [Validators.required]);
  SOBRE = new FormControl(null);
  TIPO = new FormControl(null);
  NOME_IMOB = new FormControl(null);
  EMAIL_IMOB = new FormControl(null);
  COLOR = new FormControl(null);
  distroyerEmail: Subscription

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  async contato() {
    this.sendding = false
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = 3000;

    let msg = this.MENSAGEM.value + "<br> Tipo do imóvel: " + this.TIPO.value + "<br> Sobre o imóvel: " + this.SOBRE.value
    if(this.curretRoute == "/fale-conosco") {
      msg = this.MENSAGEM.value
    }
    let jsonForEmail = {
      NOME: this.NOME.value,
      EMAIL: this.EMAIL.value,
      TELEFONE: this.TELEFONE.value,
      MENSAGEM: msg,
      NOME_IMOB: this.config.NAME_IMOB,
      EMAIL_IMOB: this.config.EMAIL_1,
      COLOR: this.config.EMAIL_COLOR,
    }
    this.distroyerEmail = await this.service.sendEmailImovel(jsonForEmail).subscribe((res: any) => {
      if (res.cod == 1) {
        this._snackBar.open('E-mail enviado com sucesso!', 'Ok', config)
        this.sendding = true
        this.NOME.setValue(null)
        this.EMAIL.setValue(null)
        this.TELEFONE.setValue(null)
        this.MENSAGEM.setValue(null)
        this.SOBRE.setValue(null)
        this.TIPO.setValue(null)
      } else {
        this._snackBar.open('Erro ao enviar o e-mail!', 'Ok', config)
        this.sendding = true
      }
    }, err => {
      this._snackBar.open('Erro ao enviar o e-mail!', 'Ok', config)
      this.sendding = true
    })

  }
  ngOnDestroy(): void {
    if (this.distroyerEmail) this.distroyerEmail.unsubscribe();
  }
}
