import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'src/app/config';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class IndicesEconomicosService {
  constructor(private http: HttpClient) { }
  config = new Config();


  getIndicesEconomicos() {
    return this.http.get(`${this.config.BASE_URL}indices-economicos`)
  }


}
