<div class="container">
    <div class="container">
        <br><br>
        <h2 class="h2 corAppColor">Trabalhe Conosco</h2>
        <p>Envie seu curriculo!</p>
    </div>
    <hr>
    <br>
    <form [formGroup]="formCV">
        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <mat-form-field class="full-width">
                        <input type="text" matInput formControlName="nome" name="nome" placeholder="Nome *">
                        <mat-error *ngIf="f['nome'].hasError('required')">
                            O nome é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <mat-form-field class="full-width">
                        <input matInput type="text" mask="(00) 0 0000-0000" placeholder="Telefone para contato*"
                            formControlName="telefone" name="telefone">
                        <mat-error *ngIf="f['telefone'].hasError('required')">
                            O número de telefone é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="form-group">
            <mat-form-field class="full-width">
                <input matInput type="text" placeholder="E-mail *" formControlName="email">
                <mat-error *ngIf="f['email'].hasError('required')">
                    O E-mail é <strong>obrigatório</strong>
                </mat-error>
                <mat-error *ngIf="f['email'].hasError('email')">
                    O E-mail está <strong>incorreto</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <mat-form-field class="full-width">
                        <input matInput type="text" placeholder="Rua*" formControlName="rua" name="rua">
                        <mat-error *ngIf="f['rua'].hasError('required')">
                            A rua é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <mat-form-field class="full-width">
                        <input matInput type="text" placeholder="Bairro *" formControlName="bairro" name="bairro">
                        <mat-error *ngIf="f['bairro'].hasError('required')">
                            O bairro é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <mat-form-field class="full-width">
                        <input matInput type="text" placeholder="Cidade*" formControlName="cidade" name="cidade">
                        <mat-error *ngIf="f['cidade'].hasError('required')">
                            A rua é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <mat-form-field class="full-width">
                        <input matInput type="text" placeholder="Número *" formControlName="numero_rua"
                            name="numero_rua">
                        <mat-error *ngIf="f['numero_rua'].hasError('required')">
                            O número é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <input type="file" name="link_cv" (change)="onFileSelect($event)" /> <br>

        <br>
        <div class="row">
            <div class="col"></div>
            <div class="col-auto">
                <button mat-raised-button color="primary" (click)="onSubmit()" >
                    Enviar
                </button>
            </div>
        </div>
    </form>
</div>
<div style="height: 400px;"></div>

<!-- [disabled]="!formCV.valid" -->