import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { Router } from '@angular/router';
import { Config } from 'src/app/config';

@Injectable({
    providedIn: 'root'
})
export class PagesService {
    config = new Config();
    constructor(private http: HttpClient, private router: Router,
        private _snackBar: MatSnackBar) { }

    getDatailsImovel(value) {
        let url = `${this.config.BASE_URL}detalhes/${this.config.COD_IMOB}/${value}`
        return this.http.get(url)
    }

    getimagensOfImovel(value) {
        let url = `${this.config.BASE_URL}galery/${this.config.COD_IMOB}/${value}`
        return this.http.get(url)
    }

    sendEmailIndicarImovel(value) {
        let url = `${this.config.BASE_URL}indicar-imovel/${this.config.COD_IMOB}`
        return this.http.post(url, value)
    }

    imoveisIndex() {
        let url = `${this.config.BASE_URL}prin/${this.config.COD_IMOB}`
        return this.http.get(url)
    }

    getBanner() {
        let url = `${this.config.BASE_URL}banner/${this.config.COD_IMOB}`
        return this.http.get(url)
    }

    getTextConfig() {
        let url = `${this.config.BASE_URL}sobre-empresa/${this.config.COD_IMOB}`
        return this.http.get(url)
    }

    sendEmailCadastroImovel(value) {
        let url = `${this.config.BASE_URL}cadastro-imovel/${this.config.COD_IMOB}`
        return this.http.post(url, value)
    }

    sendEmailAgenteImob(value) {
        let url = `${this.config.BASE_URL}agente-imob/${this.config.COD_IMOB}`
        return this.http.post(url, value)
    }

    consultaId(id) {
        let url = `${this.config.BASE_URL}visita-im-satisfacao/${this.config.COD_IMOB}/${id}`
        return this.http.get(url)
    }



    loginAdm(user, pass) {
        let url = `${this.config.BASE_URL}login-adm/${this.config.COD_IMOB}`
        this.http.post(url, { "user": user, "pass": pass }).subscribe((res: Login) => {
            console.log(res)
            if (res.login) {
                localStorage.setItem("token-imob", res.token)
                this.router.navigateByUrl("/dashboard")
            } else {
                let config = new MatSnackBarConfig();
                let horizontalPosition: MatSnackBarHorizontalPosition = 'right';
                let verticalPosition: MatSnackBarVerticalPosition = 'top';
                config.verticalPosition = verticalPosition;
                config.horizontalPosition = horizontalPosition;
                this._snackBar.open('Login Incorreto!', 'Ok', config)
            }
        })
    }

    getBanners() {
        let url = `${this.config.BASE_URL}adm/banners/${this.config.COD_IMOB}`
        return this.http.get(url)

    }

    deleteBanner(value) {
        let url = `${this.config.BASE_URL}adm/banners/${value}/${this.config.COD_IMOB}`
        return this.http.delete(url)
    }


    logoff() {
        localStorage.removeItem("token-imob")
        this.router.navigateByUrl("/pages/sobre-empresa/adm-login")
    }


    public uploadFile(data, link) {
        let uploadURL = `${this.config.BASE_URL_UPLOAD_BANNER}?link=${link}`;
        return this.http.post<any>(uploadURL, data);
    }

    public deleteFile(link) {
        let deleteUrl = `${this.config.BASE_URL_UPLOAD_BANNER}?link=${link}`;
        return this.http.delete(deleteUrl);
    }

    public sendCvFile(data) {
        let uploadURL = `${this.config.BASE_URL_UPLOAD_CV}`;
        return this.http.post<any>(uploadURL, data);
    }

    public sendDataCv(data) {
        let url = `${this.config.BASE_URL}send_cv/${this.config.COD_IMOB}`
        return this.http.post<any>(url, data);
    }

}

export class Login {
    login: boolean
    token: string
}