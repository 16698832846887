import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuscaResultadoComponent } from './components/busca/busca-resultado/busca-resultado.component';
import { AgenteImobiliarioComponent } from './pages/agente-imobiliario/agente-imobiliario.component';
import { AreaClienteComponent } from './pages/area-cliente/area-cliente.component';
import { CadastreImovelComponent } from './pages/cadastre-imovel/cadastre-imovel.component';
import { DetalhesImovelComponent } from './pages/detalhes-imovel/detalhes-imovel.component';
import { FaleConoscoComponent } from './pages/fale-conosco/fale-conosco.component';
import { IndexComponent } from './pages/index/index.component';
import { SimuladoresComponent } from './pages/simuladores/simuladores.component';
import { AdmLoginComponent } from './pages/sobre-empresa/adm-login/adm-login.component';
import { AdmComponent } from './pages/sobre-empresa/adm/adm.component';
import { SobreEmpresaComponent } from './pages/sobre-empresa/sobre-empresa.component';
import { TrabalheConoscoComponent } from './pages/trabalhe-conosco/trabalhe-conosco.component';

const routes: Routes = [
  { path: "", redirectTo: "index", pathMatch: "full" },
  { path: "index", component: IndexComponent },
  { path: "busca", component: BuscaResultadoComponent, runGuardsAndResolvers: 'always', },
  { path: "resultado/:id_im", component: DetalhesImovelComponent },
  { path: "fale-conosco", component: FaleConoscoComponent },
  { path: "sobre-empresa", component: SobreEmpresaComponent },
  { path: "area-cliente", component: AreaClienteComponent },
  { path: "agente-imobiliario", component: AgenteImobiliarioComponent },
  { path: "cadastre-imovel", component: CadastreImovelComponent },
  { path: "simuladores", component: SimuladoresComponent },
  { path: "adm-login", component: AdmLoginComponent },
  { path: "dashboard", component: AdmComponent },
  { path: "trabalhe-conosco", component: TrabalheConoscoComponent },
  
  { path: "**", redirectTo: "index" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
