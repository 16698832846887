<div class="footer">
    <br>
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h4>E-mails</h4>
                <span>Jurídico - Dr Richard Dos Reis Carvalho Pinto.</span><br>
                <span>Email - rcpfac@yahoo.com.br</span><br><br>
                <span>Financeira - Claudia Regina Ribeiro Oliveira</span><br>
                <span>Email - claudia.rro@hotmail.com</span><br><br>
                <span>Comercial – Mariane Borges de Souza</span><br>
                <span>Email - Orienteimoveis@hotmail.com</span><br><br>
                <span>Vendas - Vanderlei de Oliveira</span><br>
                <span>Email – Orienteimoveis@hotmail.com</span><br><br>
                <span>Pericia- Vanderlei de Oliveira</span><br>
                <span>Email - vanderleiperitojudicial@hotmail.com</span>
            </div>
            <div class="col-md-4">
                <h4>Contato</h4>
                <span>WhatsApp</span>
                <h5 (click)="open(config.WHATSAPP_NUMBER_1)" class="whats">{{config.WHATSAPP_NUMBER_1}}</h5>

                <span>Telefone</span>
                <h5 >{{config.PHONE_NUMBER_1}}</h5>
            </div>
            <div class="col-md-4">
                <h4>Endereço</h4>
                <h5>{{DADOS_EMPRESA.RUA}}, {{DADOS_EMPRESA.BAIRRO}}</h5>
                <h5>{{DADOS_EMPRESA.CIDADE}}</h5>
                <h5>{{DADOS_EMPRESA.CEP}}</h5>
            </div>
        </div>

    </div>
    <div style="text-align: center;">
        <span (click)="open('https://waysoft.net.br/')" class="rede-social">
            Desenvolvido por WaySoft
        </span>
    </div>
</div>