import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-sobre-empresa',
  templateUrl: './sobre-empresa.component.html',
  styleUrls: ['./sobre-empresa.component.scss']
})
export class SobreEmpresaComponent implements OnInit {

  constructor(private service: PagesService, private router: Router) { }
  async ngOnInit() {
  }
  g() {
    this.router.navigateByUrl("/adm-login")
  }

  ngOnDestroy(): void {
  }
}
