import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Config } from 'src/app/config';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PagesService } from '../pages.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-trabalhe-conosco',
  templateUrl: './trabalhe-conosco.component.html',
  styleUrls: ['./trabalhe-conosco.component.scss']
})
export class TrabalheConoscoComponent implements OnInit {

  constructor(
    private uploadService: PagesService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  formCV: FormGroup;
  config = new Config()

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.formCV = this.formBuilder.group({
      nome: [null, Validators.required],
      telefone: [null, Validators.required],
      cidade: [null, Validators.required],
      bairro: [null, Validators.required],
      rua: [null, Validators.required],
      numero_rua: [null, Validators.required],
      link_cv: [null],
      email: [null, Validators.required],
      email_imob: [this.config.EMAIL_1],
    })
  }

  async onSubmit() {
    const formData = new FormData();
    formData.append('link_cv', this.formCV.get('link_cv').value);

    await this.uploadService.sendCvFile(formData).toPromise()
      .then(async (res: any) => {
        if (!res.error) {
          await this.formCV.patchValue({ 'link_cv': res.path })
          this.postData()
        } else {
          alert(res.data)
          return;
        }

      }, err => {
        console.log(err);
        alert("Houve um erro ao enviar o curriculo!")
      });
  }

  async postData() {
    this.uploadService.sendDataCv(this.formCV.value).toPromise()
      .then((res) => {
        if (res.message == "success") {
          alert("Dados enviados com sucesso!")
          this.formCV.reset();
          this.createForm();
        }
      }, error => {
        console.log(error)
        alert("Houve um error, tente mais tarde.")
      })
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.formCV.get('link_cv').setValue(file);
    }
  }

  get f() {
    return this.formCV.controls
  }

}
