<div class="container">
    <div class="container">
        <br><br>
        <h2 class="h2 corAppColor">Agente Imobiliário</h2>
        <p>Encomende seu imóvel!</p>
    </div>
    <hr>
    <br>
    <div class="form-group">
        <label>Descreva as características abaixo:</label>
        <mat-form-field class="example-full-width">
            <textarea matInput id="exampleFormControlTextarea2" rows="5"
                placeholder="Exemplo: Desejo uma casa, com dois quartos, sala de estar, vaga de garagem...*"
                [formControl]="mensagem" name="mensagem"></textarea>
            <mat-error *ngIf="mensagem.hasError('required')">
                A mensagem é <strong>obrigatória</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="example-full-width">
            <input type="text" matInput [formControl]="nome" name="nome" placeholder="Nome *">
            <mat-error *ngIf="nome.hasError('required')">
                O nome é <strong>obrigatório</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <input matInput type="text" mask="(00) 0 0000-0000" placeholder="Celular *" [formControl]="celular"
                        name="celular">
                    <mat-error *ngIf="celular.hasError('required')">
                        O número de celular é <strong>obrigatório</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <input matInput type="text" mask="(00) 0000-0000" placeholder="Telefone" [formControl]="telefone"
                        name="telefone">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="form-group">
        <mat-form-field class="example-full-width">
            <input matInput type="text" placeholder="E-mail *" [formControl]="email">
            <mat-error *ngIf="email.hasError('required')">
                O E-mail é <strong>obrigatório</strong>
            </mat-error>
            <mat-error *ngIf="email.hasError('email')">
                O E-mail está <strong>incorreto</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <br>
    <div class="row">
        <div class="col"></div>
        <div class="col-auto">
            <button mat-raised-button color="primary" (click)="enviaEmail()" [disabled]="nome.hasError('required') ||
         mensagem.hasError('required') ||
         celular.hasError('required') ||
         email.hasError('required') ||
         email.hasError('email') ||
         sendding">
                Enviar
            </button>
        </div>
    </div>
</div>
<br><br>
<br><br>
<br><br>
<br><br>