<div class="fab-container">
    <button mat-fab class="fab-toggler" (click)="onToggleFab()" color="success">
        <mat-icon svgIcon="wpp" aria-hidden="false">
        </mat-icon>
    </button>
    <div>
        <button mat-raised-button color="success" *ngFor="let btn of buttons" (click)="openLink(btn.url)">
            <mat-icon inline=true style="max-width: 20px;" svgIcon="wpp">
            </mat-icon>
            {{btn.icon}}
        </button>

    </div>
</div>

<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" [ngClass]="{hidden: !(isHandset | async)!.matches}" fixedInViewport="false"
        [attr.role]="isHandset ? 'dialog' : 'navigation'" [mode]="(isHandset | async)!.matches ? 'over' : 'side'"
        [opened]="!(isHandset | async)!.matches">
        <mat-nav-list>
            <button mat-button [matMenuTriggerFor]="menuInstitucional">Institucional</button>
            <mat-menu #menuInstitucional="matMenu">
                <button mat-menu-item (click)="route('/sobre-empresa')">Sobre a empresa</button>
                <button mat-menu-item (click)="route('/fale-conosco')">Fale conosco</button>
            </mat-menu>
            <button *ngIf="router.url != '/busca'" mat-button [matMenuTriggerFor]="menuVendas">Vendas</button>
            <mat-menu #menuVendas="matMenu">
                <button mat-menu-item (click)="goTo(0)">Lançamentos</button>
                <button mat-menu-item (click)="route('/simuladores')">Simuladores</button>
            </mat-menu>
            <button *ngIf="router.url != '/busca'" mat-button (click)="goTo(1)">Locação</button>
            <button mat-button (click)="route('/area-cliente')">Área do Cliente</button>
            <button mat-button (click)="route('/agente-imobiliario')">Encomende seu imóvel</button>
            <button mat-button (click)="route('/cadastre-imovel')">Cadastre seu imóvel</button>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <div (click)="drawer.toggle()" *ngIf="(isHandset | async)!.matches">
                <button mat-icon-button>
                    <img src="assets/icons/menu.png" style="max-width: 27px;">
                </button>
            </div>

            <div (click)="route('/home')" style="cursor: pointer;">
                <button mat-icon-button>
                    <img src="assets/img/logo-branco.png" style="max-height: 50px;">
                </button>
            </div>
            <span class="spacer"></span>
            <div *ngIf="!(isHandset | async)!.matches">
                <button mat-button [matMenuTriggerFor]="menuInstitucional">Institucional</button>
                <mat-menu #menuInstitucional="matMenu">
                    <button mat-menu-item (click)="route('/sobre-empresa')">Sobre a empresa</button>
                    <button mat-menu-item (click)="route('/fale-conosco')">Fale conosco</button>
                </mat-menu>
                <button *ngIf="router.url != '/busca'" mat-button [matMenuTriggerFor]="menuVendas">Vendas</button>
                <mat-menu #menuVendas="matMenu">
                    <button mat-menu-item (click)="goTo(0)">Lançamentos</button>
                    <button mat-menu-item (click)="route('/simuladores')">Simuladores</button>
                </mat-menu>
                <button *ngIf="router.url != '/busca'" mat-button (click)="goTo(1)">Locação</button>
                <button mat-button (click)="route('/area-cliente')">Área do Cliente</button>
                <button mat-button (click)="route('/agente-imobiliario')">Encomende seu imóvel</button>
                <button mat-button (click)="route('/cadastre-imovel')">Cadastre seu imóvel</button>
            </div>
        </mat-toolbar>
        <div style="padding-top:64px;"></div>

        <router-outlet></router-outlet>
        <footer></footer>


    </mat-sidenav-content>
</mat-sidenav-container>

<!-- <div [ngClass]="cookieModal">
    <div class="tag"></div>
    <div class="cookie-modal-content">
        <div class="row">
            <div class="col-10">
                <p style="text-align: left;">
                    Este site utiliza cookies próprios e de terceiros para analisar sua navegação e oferecer um
                    serviço mais personalizado e publicidade conforme seus interesses. Veja os <a
                        href="https://pedraoimoveis.com.br/politicaPrivacidade/POLITICAPRIVACIDADEPEDRAO.pdf"
                        target="_blank" style="color: #00A551;">Termos de privacidade</a>. Clique em "Aceito" para
                    aceitar a utilização dos cookies.
                </p>
            </div>
            <div class="col-2">
                <button mat-raised-button color="primary" (click)="hideCookieModal()">Aceito</button>
            </div>
        </div>
    </div>
</div> -->