<br>

<div class="container">
    <h2>Simuladores</h2>
    <p>Selecione o banco de sua preferência para fazer sua simulação.</p>
    <div class="row text-center">
        <div class="col-md-4">
            <a target="_blank"
                href="https://www42.bb.com.br/portalbb/imobiliario/creditoimobiliario/simular,802,2250,2250.bbx">
                <img class="img" src="assets/icons/simuladores/bb.jpg" alt=""></a>
        </div>

        <div class="col-md-4">
            <a target="_blank"
                href="https://banco.bradesco/html/classic/produtos-servicos/emprestimo-e-financiamento/imoveis/credito-imobiliario-aquisicao-de-imoveis.shtm">
                <img class="img" src="assets/icons/simuladores/bradesco.jpg" alt=""></a>
        </div>
        <div class="col-md-4">
            <a target="_blank"
                href="http://www8.caixa.gov.br/siopiinternet-web/simulaOperacaoInternet.do?method=inicializarCasoUso">
                <img class="img" src="assets/icons/simuladores/caixa.jpg" alt=""></a>
        </div>
    </div>
    <br>
    <div class="row text-center">
        <div class="col-md-4">
            <a target="_blank" href="https://www.itau.com.br/creditos-financiamentos/imoveis/simulador/">
                <img class="img" src="assets/icons/simuladores/itau.jpg" alt=""></a>
        </div>

        <div class="col-md-4">
            <a target="_blank" href="https://creditoimobiliario.santander.com.br/credito_imobiliario/Simulador/">
                <img class="img" src="assets/icons/simuladores/santander.jpg" alt=""></a>
        </div>

        <div class="col-md-4">
            <a target="_blank"
                href="https://www.sicoob.com.br/web/creditoimobiliario/simulador">
                <img class="img" src="assets/icons/simuladores/sicoob.jpg" alt=""></a>
        </div>

    </div>
</div>
<div style="height: 250px;"></div>
<app-indices-economicos></app-indices-economicos>
