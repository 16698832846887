import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Config } from 'src/app/config';
import { MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition, MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-agente-imobiliario',
  templateUrl: './agente-imobiliario.component.html',
  styleUrls: ['./agente-imobiliario.component.scss']
})
export class AgenteImobiliarioComponent implements OnInit {

  constructor(private service: PagesService, private _snackBar: MatSnackBar) { }
  config = new Config()
  ngOnInit() {
  }
  sendding = false
  mensagem = new FormControl(null, [Validators.required]);
  nome = new FormControl(null, [Validators.required]);
  celular = new FormControl(null, [Validators.required]);
  telefone = new FormControl(null);
  email = new FormControl(null, [Validators.required, Validators.email]);
  nome_empresa = new FormControl(this.config.NAME_IMOB);
  email_empresa = new FormControl(this.config.EMAIL_1);
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  enviaEmail() {
    this.sendding = true
    let value = {
      mensagem: this.mensagem.value,
      nome: this.nome.value,
      celular: this.celular.value,
      telefone: this.telefone.value,
      email: this.email.value,
      nome_empresa: this.nome_empresa.value,
      email_empresa: this.email_empresa.value
    }
    this.service.sendEmailAgenteImob(value).subscribe((res: any) => {
      let config = new MatSnackBarConfig();
      config.verticalPosition = this.verticalPosition;
      config.horizontalPosition = this.horizontalPosition;
      config.duration = 3000;
      if (res.cod == 1) {
        this.mensagem.setValue(null)
        this.nome.setValue(null)
        this.celular.setValue(null)
        this.telefone.setValue(null)
        this.email.setValue(null)
        this.sendding = false
        this._snackBar.open(res.message, 'Ok', config)
      }
    })
  }
}
