import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BuscaRapidaService } from './components/busca/busca-rapida/busca-rapida.service';
import { Config } from './config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private breakpointObserver: BreakpointObserver,
    public router: Router,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private service: BuscaRapidaService) {
    iconRegistry.addSvgIcon(
      'wpp',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/whatsapp.svg'));
  }
  public config = new Config();
  whatsAppUrl = `https://api.whatsapp.com/send?1=pt_BR&phone=55${this.config.WHATSAPP_NUMBER_1.replace(/\D/g, "")}`
  WHATSAPP_NUMBER_1 = this.config.WHATSAPP_NUMBER_1
  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);
  buttons = [];
  fabTogglerState = 'inactive';
  fabButtons = [
    {
      icon: 'WhatsApp', url: `https://api.whatsapp.com/send?1=pt_BR&phone=55${this.config.WHATSAPP_NUMBER_1.replace(/\D/g, "")}`
    },

  ];

  openWpp() {
    this.openLink(`https://api.whatsapp.com/send?1=pt_BR&phone=55${this.config.WHATSAPP_NUMBER_1.replace(/\D/g, "")}`)
  }
  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  openLink(url) {
    open(url, '_blank')
    this.hideItems()
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  route(url) {
    this.router.navigateByUrl(url)
  }

  goTo(n) {
    this.service.searchValues = {
      CITY: "SANTA RITA DO SAPUCAÍ",
      CODIGO: "",
      FOR_SALE_OR_LOCATION: n,
      MAX_VALUE_FOR_SALE: "Todos",
      MAX_VALUE_LOCATION: "Todos",
      MIN_VALUE_FOR_SALE: "Todos",
      MIN_VALUE_LOCATION: "Todos",
      NEIGHBORHOOD: "Todos",
      TYPE: "Todos"
    }
    this.router.navigate(['/busca'])
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  cookieModal = {
    'cookie-modal': true
  }
  hideCookieModal() {
    localStorage['cookies'] = true
    this.cookieModal['cookie-modal'] = false
  }

  ngOnInit(): void {
    if (localStorage['cookies']) {
      this.hideCookieModal();
    }
  }

}


