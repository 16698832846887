<br>
<div class="container">
    <div class="row">
        <div class="col">
            <h2>Sobre a empresa</h2>
        </div>
        <div class="col-auto">
            <button mat-raised-button color="primary" (click)="g()"
                routerLinkActive="router-link-active">Administrativo</button>
        </div>
    </div>
    <br>
    <h3><strong>Nossa missão.</strong></h3>
    <p>Nossa empresa é sustentada através da credibilidade e seriedade que ao longo
        destes mais de 30 anos desenvolve seus serviços com a missão de superar as
        expectativas, buscando sempre prestar o melhor serviço. <br>
        Oriente Administradora, busca aprimora-se para atender as expectativas do
        mercado e dos seus clientes, sempre buscando sucesso na realização de seus
        negócios, dando a segurança necessária para todos que nos procuram. <br>
        Transformar sonhos em realidade é o que nos motiva, proporcionar a melhor
        parte do sonho das pessoas, na conquista do seu lar, oferecendo imóveis de
        qualidade, com preço justo e com a qualidade que nossos clientes buscam. <br>
        Tudo isso só é possível, pois trabalhamos com pessoas que têm sonhos e
        objetivos, e para nós é um prazer saber que fazemos parte da transformação
        de histórias e conquistas.
    </p>

    <h3><strong>Histórico da Empresa.</strong></h3>
    <p>Fundada desde 1982, e assumida pelos novos proprietários em 2009, a Administradora Oriente
        destaca-se cada vez mais no mercado imobiliário, fruto de um trabalho sério que possui como
        objetivo a plena satisfação de seus clientes.
    </p>

    <h3><strong>Conheça mais da empresa.</strong></h3>

    <div class="row">
        <div class="col-md-6">
            <img src="assets/img/1.jpeg" alt="">
        </div>
        <div class="col-md-6">
            <img src="assets/img/2.jpeg" alt="">
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-6">
            <img src="assets/img/4.jpeg" alt="">
        </div>
        <div class="col-md-6">
            <img src="assets/img/5.jpeg" alt="">
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-6">
            <img src="assets/img/6.jpeg" alt="">
        </div>
    </div>

</div>